import React from "react";
import Header from "./Header";
import TaskData from "./TaskData";

const TaskDetailsBox = ({ taskDetails }) => {
  return (
    <>
      <Header />
      <TaskData taskDetails={taskDetails} />
    </>
  );
};

export default TaskDetailsBox;
