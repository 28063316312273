import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../Components/CustomButton";
import Heading from "../../../Components/Heading";
import styles from "./style.module.css";
import { BiAbacus } from "react-icons/bi";
import { AiOutlinePlusCircle } from "react-icons/ai";
import Modal from "react-bootstrap/Modal";
import Input from "../../../Components/Input";
import Select from "react-select";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEmployees } from "../../../Store/Actions/employeesActions";

function CustomModal({
  show,
  handleClose,
  employees,
  setSelectedEmploy,
  setSelectedStatus,
  setSelectedProvince,
}) {
  const provinces = [
    {
      label: "A Coruña",
      value: "a coruña",
    },
    {
      label: "Álava",
      value: "álava",
    },
    {
      label: "Albacete",
      value: "albacete",
    },
    {
      label: "Alicante",
      value: "alicante",
    },
    {
      label: "Almería",
      value: "almería",
    },
    {
      label: "Asturias",
      value: "asturias",
    },
    {
      label: "Ávila",
      value: "ávila",
    },
    {
      label: "Badajoz",
      value: "badajoz",
    },
    {
      label: "Barcelona",
      value: "barcelona",
    },
    {
      label: "Burgos",
      value: "burgos",
    },
    {
      label: "Cáceres",
      value: "cáceres",
    },
    {
      label: "Cádiz",
      value: "cádiz",
    },
    {
      label: "Cantabria",
      value: "cantabria",
    },
    {
      label: "Castellón",
      value: "castellón",
    },
    {
      label: "Ciudad Real",
      value: "ciudad real",
    },
    {
      label: "Córdoba",
      value: "córdoba",
    },
    {
      label: "Cuenca",
      value: "cuenca",
    },
    {
      label: "Girona",
      value: "girona",
    },
    {
      label: "Granada",
      value: "granada",
    },
    {
      label: "Guadalajara",
      value: "guadalajara",
    },
    {
      label: "Guipúzcoa",
      value: "guipúzcoa",
    },
    {
      label: "Huelva",
      value: "huelva",
    },
    {
      label: "Huesca",
      value: "huesca",
    },
    {
      label: "Illes Balears",
      value: "illes balears",
    },
    {
      label: "Jaén",
      value: "jaén",
    },
    {
      label: "La Rioja",
      value: "la rioja",
    },
    {
      label: "Las Palmas",
      value: "las palmas",
    },
    {
      label: "León",
      value: "león",
    },
    {
      label: "Lleida",
      value: "lleida",
    },
    {
      label: "Lugo",
      value: "lugo",
    },
    {
      label: "Madrid",
      value: "madrid",
    },
    {
      label: "Málaga",
      value: "málaga",
    },
    {
      label: "Murcia",
      value: "murcia",
    },
    {
      label: "Navarra",
      value: "navarra",
    },
    {
      label: "Ourense",
      value: "ourense",
    },
    {
      label: "Palencia",
      value: "palencia",
    },
    {
      label: "Pontevedra",
      value: "pontevedra",
    },
    {
      label: "Salamanca",
      value: "salamanca",
    },
    {
      label: "Santa Cruz de Tenerife",
      value: "santa cruz de tenerife",
    },
    {
      label: "Segovia",
      value: "segovia",
    },
    {
      label: "Sevilla",
      value: "sevilla",
    },
    {
      label: "Soria",
      value: "soria",
    },
    {
      label: "Tarragona",
      value: "tarragona",
    },
    {
      label: "Teruel",
      value: "teruel",
    },
    {
      label: "Toledo",
      value: "toledo",
    },
    {
      label: "Valencia",
      value: "valencia",
    },
    {
      label: "Valladolid",
      value: "valladolid",
    },
    {
      label: "Vizcaya",
      value: "vizcaya",
    },
    {
      label: "Zamora",
      value: "zamora",
    },
    {
      label: "Zaragoza",
      value: "zaragoza",
    },
  ];

  const serviceOption = [
    {
      label: "Penidng",
      value: null,
    },
    {
      label: "In Progress",
      value: "In Progress",
    },
    {
      label: "Completed",
      value: "completed",
    },
  ];

  const [modalSelectedEmploy, setModalSelectedEmploy] = useState("");
  const [modalSelectedStatus, setModalSelectedStatus] = useState("");
  const [modalSelectedProvince, setModalSelectedProvince] = useState("");

  const formattedEmploys = employees?.map((employ) => ({
    id: employ?.id,
    label: employ?.name,
    value: employ,
  }));

  // Handle Employe Select
  const handleEmploySelect = (selectedEmploy) => {
    console.log(selectedEmploy);
    setModalSelectedEmploy(selectedEmploy?.value);
  };

  // Handle Status Select
  const handleStatusSelect = (selectedStatus) => {
    console.log(selectedStatus);
    setModalSelectedStatus(selectedStatus?.value);
  };

  const handleProvinceSelect = (selectedProvince) => {
    console.log(selectedProvince);
    setModalSelectedProvince(selectedProvince?.value);
  };

  // Handle Filter
  const handleFilter = () => {
    setSelectedEmploy(modalSelectedEmploy);
    setSelectedStatus(modalSelectedStatus);
    setSelectedProvince(modalSelectedProvince);
    handleClose();
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="d-flex justify-content-center align-items-center"
      >
        <Modal.Body
          style={{
            minWidth: "600px",
            background: "#fff",
            borderRadius: 5,
          }}
        >
          <section>
            <Heading label="Filteration" />
          </section>
          <section>
            <Select
              options={provinces}
              placeholder="Province"
              onChange={handleProvinceSelect}
            />
          </section>
          <section className="my-3">
            <Select
              options={formattedEmploys}
              placeholder="Worker"
              onChange={handleEmploySelect}
            />
          </section>
          <section>
            <Select
              options={serviceOption}
              placeholder="Status"
              onChange={handleStatusSelect}
            />
          </section>
          <section className="my-3">
            <CustomButton label="Filter" onClick={handleFilter} width="100%" />
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
}

function CalendarModal({ showCalendar, handleCloseCalendar }) {
  return (
    <>
      <Modal
        show={showCalendar}
        onHide={handleCloseCalendar}
        className="d-flex justify-content-center align-items-center"
      >
        <Modal.Body
          style={{
            minWidth: "600px",
            background: "#fff",
            borderRadius: 5,
          }}
        >
          {/* <Calendar /> */}
          <section>
            <CustomButton label="Filter" width="100%" />
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
}

const Header = ({
  setSelectedEmploy,
  setSelectedStatus,
  setSelectedDate,
  setSelectedProvince,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const employees = useSelector((state) => state.employeesReducer.employees);
  // const [showCalendar, setShowCalendar] = useState(false);

  // Getting Employees
  useEffect(() => {
    dispatch(getEmployees());
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // const handleCloseCalendar = () => setShowCalendar(false);
  // const handleShowCalendar = () => setShowCalendar(true);

  // let showDate = new Date();
  // let displayTodayDate =
  //   showDate.getDate() +
  //   "/" +
  //   showDate.getMonth() +
  //   "/" +
  //   showDate.getFullYear();

  return (
    <div className={styles.header}>
      <CustomModal
        employees={employees}
        show={show}
        handleClose={handleClose}
        setSelectedEmploy={setSelectedEmploy}
        setSelectedStatus={setSelectedStatus}
        setSelectedProvince={setSelectedProvince}
      />
      {/* <CalendarModal showCalendar={showCalendar} handleCloseCalendar={handleCloseCalendar} /> */}
      <section>
        <Heading label="Tasks" fontSize="1.5rem" color="#505050" />
      </section>
      <section>
        <span className="mx-3">
          {/* <CustomButton
            label="Calendar"
            width="150px"
            // icon={<BiAbacus fontSize={25} />}
            onClick={handleShowCalendar}
          /> */}
          <CustomButton
            label="Clear Date"
            width="150px"
            onClick={() => setSelectedDate("")}
            className={"mx-2"}
          />
          <CustomButton
            label="Filteration"
            width="150px"
            icon={<BiAbacus fontSize={25} />}
            onClick={handleShow}
            className={"mx-2"}
          />
        </span>
        {/* <CustomButton
          label="Create Client"
          width="180px"
          // icon={<BiAbacus fontSize={25} />}
          icon={<AiOutlinePlusCircle fontSize={25} />}
          // onClick={() => navigate("/dashboard/add-admin")}
        /> */}
      </section>
    </div>
  );
};

export default Header;
