import {
    GET_TASK_FAILURE,
    GET_TASK_REQUEST,
    GET_TASK_SUCCESS,
    GET_EMPLOYEE_TASK_FAILURE,
    GET_EMPLOYEE_TASK_REQUEST,
    GET_EMPLOYEE_TASK_SUCCESS,
    DELETE_TASK_FAILURE,
    DELETE_TASK_REQUEST,
    DELETE_TASK_SUCCESS
} from "../Constants/taskConsts";

const initialState = {
    isLoading: false,
    tasks: [],
    employTasks: [],
    error: null
};

export const tasksReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TASK_REQUEST:
        case DELETE_TASK_REQUEST:
        case GET_EMPLOYEE_TASK_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case GET_TASK_SUCCESS:
            return {
                ...state,
                isLoading: false,
                tasks: action.payload
            };
        case DELETE_TASK_SUCCESS:
            const updatedTasks = state.tasks.filter(
                (task) => task.id !== action.payload
            );
            return {
                ...state,
                tasks: updatedTasks,
                isLoading: false,
            };
        case GET_EMPLOYEE_TASK_SUCCESS:
            return {
                ...state,
                isLoading: false,
                employTasks: action.payload
            };
        case GET_TASK_FAILURE:
        case DELETE_TASK_FAILURE:
        case GET_EMPLOYEE_TASK_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        default:
            return state;
    }
};
