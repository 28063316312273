import React, { useState } from "react";
// import {
//   AiOutlineCheck,
//   AiOutlineDelete,
//   AiOutlineEdit,
//   AiOutlineEye,
// } from "react-icons/ai";
import styles from "./style.module.css";
// import Switch from "react-switch";
// import CustomSelect from "../../../Components/CustomSelect";
import Input from "../../../Components/Input";
// import Heading from "../../../Components/Heading";

const CreatePass = ({ formValues, setFormValues, handleInputChange }) => {

  return (
    <>
      <div className={`${styles.mainContainer}`}>
        <div className={`${styles.inputGroup}`}>
          <div className={`${styles.input}`}>
            <Input
              type='password'
              // placeholder={'Password'}
              placeholder={'Contraseña'}
              value={formValues.password}
              name='password'
              onChange={handleInputChange}
            />
          </div>
          <div className={`${styles.input}`}>
            <Input
              type='password'
              // placeholder={'Confirm Password'}
              placeholder={'confirmar Contraseña'}
              value={formValues.cnfrmPass}
              name='cnfrmPass'
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreatePass;
