import React from "react";
import styles from "./style.module.css";
// import pic1 from "../../../../Assets/list1.png";
// import pic3 from "../../../../Assets/list3.png";
// import Heading from "../../../../Components/Heading";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"

const ClientData = ({ clientDetails }) => {

  // console.log(clientDetails);

  // Calculate Age
  function calculateAge(dateOfBirth) {
    const dob = new Date(dateOfBirth);
    const dobYear = dob.getFullYear();
    const currentYear = new Date().getFullYear();
    const age = currentYear - dobYear;
    return age;
  }
  const age = calculateAge(clientDetails?.client?.dateOfBirth);

  return (
    <>
      <div>
        <div className={`${styles.listContainer}`}>
          <div className={`${styles.listDiv}`}>
            <h6>Client</h6>
            <p>{clientDetails?.client?.name}</p>
          </div>
          {/* <div className={`${styles.listDiv}`}>
            <h6>Coordinator</h6>
            <p>John Michel</p>
          </div> */}
          <div className={`${styles.listDiv}`}>
            <h6>Email</h6>
            <p>{clientDetails?.client?.email}</p>
          </div>
          <div className={`${styles.listDiv}`}>
            <h6>Phone</h6>
            <p>{clientDetails?.client?.phone}</p>
          </div>
          {/* <div className={`${styles.listDiv}`}>
            <h6>Date & Time</h6>
            <p>{clientDetails?.date}</p>
            <p>{clientDetails?.time}</p>
          </div> */}
          {/* <div className={`${styles.listDiv}`}>
            <h6>Task</h6>
            <p>{clientDetails?.name}</p>
          </div> */}
          <div className={`${styles.listDiv}`}>
            <h6>Age</h6>
            <p>{age}</p>
          </div>
        </div>
        <div className={`${styles.descContainer}`}>
          <h6>Home Address</h6>
          <p>{clientDetails?.client?.address}</p>
        </div>
        <div className={`${styles.descContainer}`}>
          <h6>Comments</h6>
          <p>
            {clientDetails?.description}
          </p>
        </div>

        {
          clientDetails?.photo &&
          <div className={`mt-3 ${styles.imgContainer}`}>
            <h5>Client Photos</h5>
            <div>
              {clientDetails?.photo?.map((item) => (
                <TransformWrapper
                  initialScale={1}
                  initialPositionX={0}
                  initialPositionY={0}
                >
                  <TransformComponent>
                    <img
                      src={`http://136.244.113.52/${item?.photo}`}
                      width={"350px"}
                      height={"350px"}
                      alt="Client"
                    />
                  </TransformComponent>
                </TransformWrapper>
              ))}
            </div>
          </div>
        }
        {/* <div className={`mt-3 ${styles.imgContainer}`}>
          <h5>Task Photos</h5>
          <div>
            {clientDetails?.photos?.map((item) => (
              // <img src={`http://136.244.113.52/${item}`} alt={"pic1"} />
              <img src={pic3} alt={"pic3"} />
            ))}
          </div>
        </div> */}
      </div>
    </>
  );
};

export default ClientData;
