import React from "react";
import styles from "./style.module.css";
const Input = ({
  placeholder,
  type,
  onChange,
  name,
  value,
  checked,
  disabled,
}) => {
  return (
    <input
      className={styles.input}
      type={type || "text"}
      placeholder={placeholder}
      onChange={(e) => onChange(e)}
      name={name}
      value={value}
      checked={checked}
      disabled={disabled}
    />
  );
};

export default Input;
