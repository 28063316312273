import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { sideActiveAction } from "../../Store/Actions/sideActiveAction";
import DataTable from "./Creating";
import Header from "./Header";
import styles from "./createcoordinator.module.css";
import Creating from "./Creating";
const CreateCoordinator = () => {

  const dispatch = useDispatch();
  const coordinatorStates = useSelector((state) => state.coordinatorReducer);

  useEffect(() => {
    dispatch(sideActiveAction("Coordinators"));
  }, []);

  return (
    <div>
      <section className={` mt-4 ${styles.mainDiv}`}>
        <Header />
        <Creating />
      </section>
    </div>
  );
};

export default CreateCoordinator;
