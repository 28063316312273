import React from "react";
import styles from "./style.module.css";
const CustomSelect = ({ children, width, onSelect, onChange, name, value, disabled }) => {
  return (
    <select
      className={styles.select}
      onSelect={onSelect && onSelect}
      onChange={onChange && onChange}
      style={{ width: width && width }}
      name={name}
      value={value}
      disabled={disabled && disabled}
    >
      {children && children}
    </select>
  );
};

export default CustomSelect;
