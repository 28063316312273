import React from "react";
import styles from "./style.module.css";
import Time from "../../../../Assets/Time.png";
import Heading from "../../../../Components/Heading";
import pic1 from "../../../../Assets/list1.png"

const ServiceData = ({ serviceTime }) => {

  // console.log(serviceTime);

  return (
    <>
      <div className="d-flex">
        <div className={`mt-3 ${styles.imgContainer}`}>
          {/* <section>
            <Heading label="Service Time" fontSize="1.5rem" color="#505050" />
          </section> */}
          <section className="d-flex">
            <div className={`mt-3 ${styles.timeContainer}`}>
              <img src={Time} alt={"Time"} />
              <h6>Start Time</h6>
              <h5>{serviceTime?.startTime}</h5>
            </div>
            <div className={`mt-3 ${styles.timeContainer}`}>
              <img src={Time} alt={"Time"} />
              <h6>End Time</h6>
              <h5>{serviceTime?.endTime}</h5>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default ServiceData;
