import React, { useState } from "react";
import {
  AiOutlineCheck,
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineEye,
} from "react-icons/ai";
import styles from "./style.module.css";
import Switch from "react-switch";
import CustomSelect from "../../../Components/CustomSelect";
import { useDispatch } from "react-redux";
import { deleteCoord } from "../../../Store/Actions/coordinatorActions";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../../Store/Actions/toastActions";
import API from "../../../Config/Api";

const DataTable = ({ tableData, headerData }) => {
  const [status, setStatus] = useState("active");
  const toggleStatus = () => {
    setStatus((curr) => (curr === "active" ? "Inactive" : "active"));
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDeleteCoord = (coordinatorId) => {
    dispatch(deleteCoord(coordinatorId));
  };
  const request = async (province) => {
    if (!province) {
      dispatch(showToast("Province Required", "info"));
      return;
    }
    try {
      const response = await API.post("/requests", {
        province,
      });
      console.log("res", response);
      navigate("/dashboard/request-province", { state: response?.data?.data });
      // dispatch(showToast("Task Deleted Successfully", "info"));
    } catch (error) {}
  };

  return (
    <div className={styles.tableParent}>
      <table>
        <thead>
          <tr>
            <td>
              <input type="checkbox" className={styles.checkBox} />
            </td>
            {headerData?.map((value) => {
              return (
                <td
                  // style={{width:"150px"}}
                  key={value}
                  className={styles.tHeading}
                >
                  {value}
                </td>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {tableData?.map((value) => {
            // console.log(value)
            const createdDate = new Date(value?.created_at);
            const createdDateFormat = createdDate.toDateString();

            // const updatedDate = new Date(value?.updated_at);
            // const updatedDateFormat = updatedDate.toDateString();

            return (
              <tr key={value?.id}>
                <td>
                  <input type="checkbox" className={styles.checkBox} />
                </td>
                <td
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => request(value?.province)}
                >
                  {value?.name}
                </td>
                <td>{value?.city}</td>
                <td>{value?.province}</td>
                <td>{createdDateFormat}</td>

                {/* <td>{updatedDateFormat}</td> */}

                <td className={styles.actionTd}>
                  <span onClick={() => handleDeleteCoord(value?.id)}>
                    <AiOutlineDelete />
                  </span>
                  <span className="mx-2">
                    <AiOutlineEdit
                      onClick={() =>
                        navigate("/dashboard/edit-coordinator", {
                          state: { value },
                        })
                      }
                    />
                  </span>
                  <span>
                    <AiOutlineEye
                      onClick={() =>
                        navigate("/dashboard/edit-coordinator", {
                          state: { value, viewOnly: true },
                        })
                      }
                    />
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;
