import {
    GET_ACTIVITIES_FAILURE,
    GET_ACTIVITIES_REQUEST,
    GET_ACTIVITIES_SUCCESS
} from "../Constants/activitiesConsts";

const initialState = {
    isLoading: false,
    activities: [],
    error: null
};

export const activitiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ACTIVITIES_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case GET_ACTIVITIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                activities: action.payload
            };
        case GET_ACTIVITIES_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        default:
            return state;
    }
};
