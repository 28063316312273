import React, { useState } from "react";
import {
  AiOutlineCheck,
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineEye,
} from "react-icons/ai";
import styles from "./style.module.css";
import Switch from "react-switch";
import CustomSelect from "../../../Components/CustomSelect";
import Input from "../../../Components/Input";
import Heading from "../../../Components/Heading";
import citiesData from "../../../Assets/Data/cities.json"
import provinceData from "../../../Assets/Data/province.json"
import Select from "react-select"
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEmployees } from "../../../Store/Actions/employeesActions";

const Creating = ({ formValues, setFormValues, handleInputChange, viewOnly, coordData }) => {

  const [cities, setCities] = useState([]);
  const [province, setProvince] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [isCitySelected, setIsCitySelected] = useState(false);
  const [singleOption, setSingleOption] = useState(null);

  const dispatch = useDispatch();
  const employeeArr = useSelector((state) => state.employeesReducer.employees);
  // console.log(employeeArr)

  useEffect(() => {
    dispatch(getEmployees())
  }, [dispatch])

  // Get Cities and Province from JSON
  useEffect(() => {
    // Cities
    const formattedArray = citiesData.map((cityObj) => ({
      value: cityObj,
      label: cityObj.city,
    }));
    setCities(formattedArray);

    // Provinces

    const formattedProvince = provinceData.map((city) => ({
      value: city,
      label: city,
    }));
    setProvince(formattedProvince);

  }, []);

  useEffect(() => {
    // Employees
    const formattedEmployees = employeeArr.map((employee) => ({
      value: employee,
      label: employee.name,
    }));
    setEmployees(formattedEmployees);

    // Show View Only Employee
    if (viewOnly) {
      const employee = employeeArr?.find((emp) => emp?.id == coordData?.employId);

      // console.log("employeeArr", employeeArr)
      // console.log("coordData", coordData)
      console.log(employee)

      if (employee) {
        const option = { value: employee, label: employee?.name };
        setSingleOption(option);
        setFormValues({ ...formValues, ["employData"]: employee, ["name"]: employee?.name });
      }
    }
  }, [employeeArr])
  // Handle Select City
  const handleSelectCity = (e) => {
    setFormValues({ ...formValues, ["city"]: e.value.city });
  };
  // Handle Radio Change
  const handleRadioChange = (event) => {
    setIsCitySelected(event.target.value === "city");
    setFormValues({
      ...formValues,
      isCitySelected: event.target.value === "city",
    });
  };
  // Handle Select Province
  const handleSelectProvince = (e) => {
    setFormValues({ ...formValues, ["province"]: e.value });
  };
  // Handle Select Employee
  const handleSelectEmployee = (e) => {
    setFormValues({ ...formValues, ["employData"]: e.value, ["name"]: e.value.name });
  }

  return (
    <>
      <div className={`${styles.mainContainer}`}>
        <div className={`${styles.nameInput}`}>
          <Select
            onChange={handleSelectEmployee}
            className={styles.selectBox}
            options={viewOnly ? [singleOption] : employees}
            // placeholder="Select Employee"
            placeholder={viewOnly ? singleOption?.label : "Seleccionar empleada"}
            isDisabled={viewOnly}
          />
        </div>
        <div className={`${styles.inputGroup}`}>
          <div className={`${styles.input}`}>
            {/* <Input disabled={viewOnly} placeholder={"Nationality"} name="nif" value={formValues.employData?.nif} onChange={handleInputChange} /> */}
            <Input disabled={viewOnly} placeholder={"Nacionalidad"} name="nif" value={formValues.employData?.nif} onChange={handleInputChange} />
          </div>
          <div className={`${styles.input}`}>
            {/* <Input disabled={viewOnly} placeholder={"Phone"} name="phone" value={formValues.employData?.phone} onChange={handleInputChange} /> */}
            <Input disabled={viewOnly} placeholder={"Teléfono"} name="phone" value={formValues.employData?.phone} onChange={handleInputChange} />
          </div>
        </div>
        <div className={`${styles.inputGroup}`}>
          <div className={`${styles.input}`}>
            {/* <Input disabled={viewOnly} placeholder={"Email"} name="email" value={formValues.employData?.email} onChange={handleInputChange} /> */}
            <Input disabled={viewOnly} placeholder={"Correo electrónico"} name="email" value={formValues.employData?.email} onChange={handleInputChange} />
          </div>
          <div className={`${styles.input}`}>
            {/* <Input disabled={viewOnly} placeholder={"Date"} name="dateOfBirth" value={formValues.employData?.dateOfBirth} onChange={handleInputChange} /> */}
            <Input disabled={viewOnly} placeholder={"Fecha"} name="dateOfBirth" value={formValues.employData?.dateOfBirth} onChange={handleInputChange} />
          </div>
        </div>
        <div className={`${styles.inputGroup}`}>
          <div className={`${styles.input}`}>
            <CustomSelect disabled={viewOnly} width={"100%"}>
              {/* <option>Gender</option> */}
              <option>Género</option>
              <option>Male</option>
              <option>Female</option>
            </CustomSelect>
          </div>
          <div className={`${styles.input}`}>
            {/* <Input disabled={viewOnly} placeholder={"Address"} name="address" value={formValues.employData?.address} onChange={handleInputChange} /> */}
            <Input disabled={viewOnly} placeholder={"DIRECCIÓN"} name="address" value={formValues.employData?.address} onChange={handleInputChange} />
          </div>
        </div>
        <div className={`${styles.radioContainer}`}>
          <div className={`${styles.radioGroup}`}>
            <div className={`${styles.radioInp}`}>
              <Input
                name={"radio"}
                type="radio"
                value="city"
                checked={isCitySelected}
                onChange={handleRadioChange}
              />
            </div>
            <div className={`${styles.radioTxt}`}>
              {/* <Heading label={"For City"} fontSize={"0.8rem"} /> */}
              <Heading label={"Para la ciudad"} fontSize={"0.8rem"} />
            </div>
          </div>
          <div className={`${styles.radioGroup}`}>
            <div className={`${styles.radioInp}`}>
              <Input
                name={"radio"}
                type="radio"
                value="province"
                checked={!isCitySelected}
                onChange={handleRadioChange}
              />
            </div>
            <div className={`${styles.radioTxt}`}>
              <Heading label={"Por Provincia"} fontSize={"0.8rem"} />
            </div>
          </div>
        </div>
        <div className={`${styles.inputGroup}`}>
          {
            viewOnly
              ? (
                <>
                  <div className={`${styles.input}`}>
                    <Select
                      onChange={handleSelectProvince}
                      className={styles.selectBox}
                      options={province}
                      // placeholder="Select Province"
                      placeholder="Seleccione Provincia"
                      isDisabled={viewOnly}
                    />
                  </div>
                  <div className={`${styles.input}`}>
                    <Select
                      onChange={handleSelectCity}
                      className={styles.selectBox}
                      options={cities}
                      // placeholder="Select City"
                      placeholder="Ciudad selecta"
                      isDisabled={viewOnly}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className={`${styles.input}`}>
                    <Select
                      onChange={handleSelectProvince}
                      className={styles.selectBox}
                      options={province}
                      // placeholder="Select Province"
                      placeholder="Seleccione Provincia"
                      isDisabled={isCitySelected}
                    />
                  </div>
                  <div className={`${styles.input}`}>
                    <Select
                      onChange={handleSelectCity}
                      className={styles.selectBox}
                      options={cities}
                      // placeholder="Select City"
                      placeholder="Ciudad selecta"
                      isDisabled={!isCitySelected}
                    />
                  </div>
                </>
              )
          }
        </div>

        {/* <div className={`${styles.inputGroup}`}>
          <CustomSelect width={"48%"}>
            <option>Male</option>
            <option>
              <AiOutlineEye color="#fff" />
              Male
            </option>
            <option>
              <AiOutlineEdit color="#fff" /> Female
            </option>
          </CustomSelect>
          <div className={`${styles.input}`}>
            <Input placeholder={"Address"} />
          </div>
        </div> */}

        {/* <div className={`${styles.nameInput}`}>
          <Input placeholder={"City,Province"} />
        </div> */}
      </div>
    </>
  );
};

export default Creating;
