import React from "react";
import Header from "./Header";
import ClientData from "./ClientData";

const ClientDetails = ({ clientDetails }) => {
  return (
    <>
      <Header />
      <ClientData clientDetails={clientDetails} />
    </>
  );
};

export default ClientDetails;
