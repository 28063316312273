import API from "../../Config/Api";
import {
    GET_ACTIVITIES_FAILURE,
    GET_ACTIVITIES_REQUEST,
    GET_ACTIVITIES_SUCCESS
} from "../Constants/activitiesConsts"

// Get Activities
export const getActivities = () => async (dispatch) => {

    dispatch({
        type: GET_ACTIVITIES_REQUEST
    })
    try {
        const res = await API.post('/get-activities');
        // console.log(res)
        dispatch({
            type: GET_ACTIVITIES_SUCCESS,
            payload: res?.data?.data
        });
    } catch (error) {
        dispatch({
            type: GET_ACTIVITIES_FAILURE,
            payload: error
        });
    }
};