import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { sideActiveAction } from "../../Store/Actions/sideActiveAction";
import DataTable from "./DataTable";
import Header from "./Header";
import { getEmployeeTasks } from "../../Store/Actions/taskActions"
import { useLocation } from "react-router-dom";
import { useState } from "react";

const EmployeTaskTable = () => {

  const [selectedDate, setSelectedDate] = useState("");
  const { state } = useLocation();
  const dispatch = useDispatch();
  const employTaskState = useSelector((state) => state.tasksReducer);

  console.log(employTaskState)

  useEffect(() => {
    dispatch(sideActiveAction("Employees"));
    dispatch(getEmployeeTasks(state?.id))
  }, []);

  const headerData = [
    "Nombre de la tarea", //"Task Name",
    "nombre del cliente", //"Client Name",
    "Nombre de empleado", //"Employee Name",
    "Fecha", // "Date",
    "Estado", //"Status",
    // "Comportamiento", //"Actions",
  ];
  const employTasksArr = employTaskState?.employTasks;

  return (
    <div>
      <Header setSelectedDate={setSelectedDate} />
      <section
        className={
          employTaskState.isLoading
            ? "d-flex justify-content-center align-items-center"
            : "mt-4"
        }
      >
        {employTaskState.isLoading ? (
          <Spinner style={{ color: "#6E528D" }} />
        ) : (
          <DataTable
            selectedDate={selectedDate}
            headerData={headerData}
            tableData={employTasksArr}
          />
        )}
      </section>
    </div>
  );
};

export default EmployeTaskTable;
