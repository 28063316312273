import {
    GET_EMPLOYEES_REQUEST,
    GET_EMPLOYEES_FAILURE,
    GET_EMPLOYEES_SUCCESS,
    CREATE_EMPLOYEES_REQUEST,
    CREATE_EMPLOYEES_FAILURE,
    CREATE_EMPLOYEES_SUCCESS,
    DELETE_EMPLOYEE_REQUEST,
    DELETE_EMPLOYEE_FAILURE,
    DELETE_EMPLOYEE_SUCCESS,
    EDIT_EMPLOYEE_FAILURE,
    EDIT_EMPLOYEE_REQUEST,
    EDIT_EMPLOYEE_SUCCESS
} from "../Constants/employeesConts";

const initialState = {
    employees: [],
    createEmployeeRes: "",
    // deleteEmployeeRes: "",
    editEmployeeRes: "",
    isLoading: false,
    error: null
};

export const employeesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_EMPLOYEES_REQUEST:
        case CREATE_EMPLOYEES_REQUEST:
        case DELETE_EMPLOYEE_REQUEST:
        case EDIT_EMPLOYEE_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case GET_EMPLOYEES_FAILURE:
        case CREATE_EMPLOYEES_FAILURE:
        case DELETE_EMPLOYEE_FAILURE:
        case EDIT_EMPLOYEE_FAILURE:
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };
        case GET_EMPLOYEES_SUCCESS:
            return {
                ...state,
                employees: action.payload,
                isLoading: false
            };
        case CREATE_EMPLOYEES_SUCCESS:
            return {
                ...state,
                createEmployeeRes: action.payload,
                isLoading: false
            };
        case DELETE_EMPLOYEE_SUCCESS:
            const updatedEmployees = state.employees.filter(
                (employee) => employee.id !== action.payload
            );
            return {
                ...state,
                employees: updatedEmployees,
                isLoading: false,
            };
        case EDIT_EMPLOYEE_SUCCESS:
            return {
                ...state,
                editEmployeeRes: action.payload,
                isLoading: false,
            };
        default:
            return state;
    }
};
