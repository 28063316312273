import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../Components/CustomButton";
import Heading from "../../../Components/Heading";
import Input from "../../../Components/Input";
import styles from "./style.module.css";
import API from "../../../Config/Api";
import { useDispatch, useSelector } from "react-redux";
import { loginSuperAdmin } from "../../../Store/Actions/loginAction";
import { Spinner } from "react-bootstrap";
import { sideActiveAction } from "../../../Store/Actions/sideActiveAction";

const Form = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginState = useSelector((state) => state.loginReducer);

  // console.log(loginState)

  const login = async (e) => {
    e.preventDefault();
    navigate("/dashboard/tasks");
    dispatch(sideActiveAction("Dashboard"));
    // if (email === "" || password === "") {
    //   alert("Require Field are missing");
    // } else if (email && password) {
    //   dispatch(loginSuperAdmin(email, password));

    //   if (
    //     loginState?.data?.status === 200 ||
    //     loginState?.data?.status === 201
    //   ) {
    //     alert("Logged In successfully");
    //     navigate("/dashboard");
    //   }
    //   if (
    //     loginState?.data?.status === 400 ||
    //     loginState?.data?.status === 401
    //   ) {
    //     alert("Email or Password is Incorrect");
    //   }
    // }
  };
  return (
    <form className={styles.formBox} onSubmit={login}>
      <section className={`text-center ${styles.heading}`}>
        <Heading label="Login" color="#6E528D" />
      </section>
      <section className={styles.inpBox}>
        <Input placeholder="Enter Email" onChange={setEmail} type="text" />
      </section>
      <section className={styles.inpBox}>
        <Input
          placeholder="Enter Passwrod"
          type="password"
          onChange={setPassword}
        />
      </section>
      <section className="d-flex justify-content-center align-items-center">
        {loginState.loading ? (
          <Spinner style={{ color: "#6E528D" }} />
        ) : (
          <CustomButton label="Login" width="100%" />
        )}
      </section>
    </form>
  );
};

export default Form;
