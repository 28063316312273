import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { sideActiveAction } from "../../Store/Actions/sideActiveAction";
import DataTable from "./DataTable";
import Header from "./Header";
import { getClients } from "../../Store/Actions/clientActions";

const Clients = () => {
  const dispatch = useDispatch();
  const clientStates = useSelector((state) => state.clientsReducer);

  useEffect(() => {
    dispatch(sideActiveAction("Clients"));
    dispatch(getClients());
  }, [clientStates?.createClientRes, clientStates?.editClientRes]);

  console.log(clientStates);

  const headerData = [
    "nombre del cliente", //"Client Name",
    "Correo electrónico", // "Email",
    "Teléfono", //"Phone",
    "DIRECCIÓN", //"Address",
    "Comportamiento", //"Actions",
  ];
  const clientsArr = clientStates?.clients;

  return (
    <div>
      <Header />
      <section
        className={
          clientStates.isLoading
            ? "d-flex justify-content-center align-items-center"
            : "mt-4"
        }
      >
        {clientStates.isLoading ? (
          <Spinner style={{ color: "#6E528D" }} />
        ) : (
          <DataTable headerData={headerData} tableData={clientsArr} />
        )}
      </section>
    </div>
  );
};

export default Clients;
