import { useEffect, useState } from "react";
import styles from "./style.module.css";
import { BsUpload } from "react-icons/bs";
import CustomSelect from "../../../Components/CustomSelect";
import Input from "../../../Components/Input";
import API from "../../../Config/Api";
import Select from "react-select"
import citiesData from "../../../Assets/Data/cities.json"
import provinceData from "../../../Assets/Data/province.json"

const CreateEmployeeForm = ({ formValues, setFormValues, handleInputChange, handlePhotoChange }) => {


  const [displayImgUrl, setDisplayImgUrl] = useState("");
  const [cities, setCities] = useState([]);
  const [province, setProvince] = useState([]);

  // Get Cities and Province from JSON
  useEffect(() => {
    // Cities
    const formattedArray = citiesData.map((cityObj) => ({
      value: cityObj,
      label: cityObj.city,
    }));
    setCities(formattedArray);

    // Provinces

    const formattedProvince = provinceData.map((city) => ({
      value: city,
      label: city,
    }));
    setProvince(formattedProvince);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (formValues?.photo) {
        try {

          const formData = new FormData();
          formData.append('image', formValues.photo);
          const response = await API.post('/get-path', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          // console.log(response?.data)
          setDisplayImgUrl(response?.data?.data);

        } catch (error) {
          console.log(error);
        }
      }
    };
    fetchData();
  }, [formValues?.photo]);

  // Handle Select City
  const handleSelectCity = (e) => {
    setFormValues({ ...formValues, ["city"]: e.value.city });
  };

  // Handle Select Province
  const handleSelectProvince = (e) => {
    setFormValues({ ...formValues, ["province"]: e.value });
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  return (
    <div className={styles.mainContainer}>
      <div className={displayImgUrl ? styles.displayBox : styles.fileUpload}>
        {
          displayImgUrl
            ? <>
              <label className={`mt-2 ${styles.imgBoxLabel}`}>
                <img width={"100%"} height={"100%"} src={`http://136.244.113.52/${displayImgUrl}`} />
                <input type="file" className={styles.file} onChange={handlePhotoChange} />
              </label>
            </>
            : <>
              <BsUpload fontSize={"1.4rem"} color="grey" />
              <label className={`mt-2 ${styles.label}`}>
                Subir foto
                <input type="file" className={styles.file} onChange={handlePhotoChange} />
              </label>
            </>
        }
      </div>
      <div className={`mt-3 ${styles.nameInput}`}>
        {/* <Input placeholder={"Employee Name"} name="name" value={formValues.name} onChange={handleInputChange} /> */}
        <Input placeholder={"Nombre de empleado"} name="name" value={formValues.name} onChange={handleInputChange} />
      </div>
      <div className={`mt-3 ${styles.inputGroup}`}>
        <div className={`${styles.input}`}>
          {/* <Input placeholder={"Email"} name="email" value={formValues.email} onChange={handleInputChange} /> */}
          <Input placeholder={"Correo electrónico"} name="email" value={formValues.email} onChange={handleInputChange} />
        </div>
        <div className={`${styles.input}`}>
          {/* <Input placeholder={"Phone Number"} name="phone" value={formValues.phone} onChange={handleInputChange} /> */}
          <Input placeholder={"Número de teléfono"} name="phone" value={formValues.phone} onChange={handleInputChange} />
        </div>
      </div>
      <div className={`mt-3 ${styles.inputGroup}`}>
        <div className={`${styles.input}`}>
          <Select
            onChange={handleSelectProvince}
            className={styles.selectBox}
            options={province}
            // placeholder="Select Province"
            placeholder="Seleccione Provincia"
          />
        </div>
        <div className={`${styles.input}`}>
          <Select
            onChange={handleSelectCity}
            className={styles.selectBox}
            options={cities}
            // placeholder="Select City"
            placeholder="Ciudad selecta"
          />
        </div>
      </div>
      <div className={`mt-3 ${styles.inputGroup}`}>
        <div className={`${styles.input}`}>
          {/* <Input placeholder={"Nif"} name="nif" value={formValues.nif} onChange={handleInputChange} /> */}
          <Input placeholder={"Nif"} name="nif" value={formValues.nif} onChange={handleInputChange} />
        </div>
        <CustomSelect name={"gender"} onChange={handleSelectChange} width={"48%"}>
          {/* <option>Gender</option> */}
          <option>Género</option>
          <option>Male</option>
          <option>Female</option>
        </CustomSelect>
      </div>

      <div className={`mt-3 ${styles.inputGroup}`}>
        <div className={`${styles.input}`}>
          {/* <Input placeholder={"Date of Birth"} name="dateOfBirth" value={formValues.dateOfBirth} onChange={handleInputChange} /> */}
          <Input placeholder={"Fecha de nacimiento"} name="dateOfBirth" value={formValues.dateOfBirth} onChange={handleInputChange} />
        </div>
        <div className={`${styles.input}`}>
          {/* <Input placeholder={"Street Address"} name="address" value={formValues.address} onChange={handleInputChange} /> */}
          <Input placeholder={"Dirección"} name="address" value={formValues.address} onChange={handleInputChange} />
        </div>
      </div>
    </div>
  );
};

export default CreateEmployeeForm;
