import React, { useState } from "react";
import {
  AiOutlineCheck,
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineEye,
} from "react-icons/ai";
import styles from "./style.module.css";
import Switch from "react-switch";
import CustomSelect from "../../../Components/CustomSelect";
import { useDispatch } from "react-redux";
import { deleteClient } from "../../../Store/Actions/clientActions";
import { useNavigate } from "react-router-dom";
import API from "../../../Config/Api";
import { showToast } from "../../../Store/Actions/toastActions";

const DataTable = ({ tableData, headerData }) => {
  const [status, setStatus] = useState("active");
  const toggleStatus = () => {
    setStatus((curr) => (curr === "active" ? "Inactive" : "active"));
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDelete = (clientId) => {
    dispatch(deleteClient(clientId));
  };
  const request = async (clientId) => {
    try {
      const response = await API.post("/requests", {
        clientId,
      });
      console.log("res", response);
      navigate("/dashboard/request-province", { state: response?.data?.data });
    } catch (error) {}
  };

  return (
    <div className={styles.tableParent}>
      <table>
        <thead>
          <tr>
            <td>
              <input type="checkbox" className={styles.checkBox} />
            </td>
            {headerData?.map((value) => {
              return (
                <td
                  // style={{width:"150px"}}
                  key={value}
                  className={styles.tHeading}
                >
                  {value}
                </td>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {tableData?.map((value) => {
            // console.log(value)
            // const createdDate = new Date(value?.created_at);
            // const createdDateFormat = createdDate.toDateString();

            // const updatedDate = new Date(value?.updated_at);
            // const updatedDateFormat = updatedDate.toDateString();

            return (
              <tr key={value?.id}>
                <td>
                  <input type="checkbox" className={styles.checkBox} />
                </td>
                <td
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => request(value?.id)}
                >
                  {value?.name}
                </td>
                <td style={{ width: "200px", wordBreak: "break-all" }}>
                  {value?.email}
                </td>
                <td>{value?.phone}</td>
                <td>{value?.address}</td>

                <td className={styles.actionTd}>
                  <span onClick={() => handleDelete(value?.id)}>
                    <AiOutlineDelete />
                  </span>
                  <span
                    onClick={() =>
                      navigate("/dashboard/edit-client", { state: { value } })
                    }
                    className="mx-2"
                  >
                    <AiOutlineEdit />
                  </span>
                  <span>
                    <AiOutlineEye
                      onClick={() =>
                        navigate("/dashboard/edit-client", {
                          state: { value, viewOnly: true },
                        })
                      }
                    />
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;
