import {
    GET_ADMINS_REQUEST,
    GET_ADMINS_SUCCESS,
    GET_ADMINS_FAILURE,
    GET_PUBLIC_ADMINS_REQUEST,
    GET_PUBLIC_ADMINS_SUCCESS,
    GET_PUBLIC_ADMINS_FAILURE
} from '../Constants/getAdminConstants';

const initialState = {
    admins: [],
    publicAdmins: [],
    isLoading: false,
    error: null
};

export const getAdminsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ADMINS_REQUEST:
        case GET_PUBLIC_ADMINS_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: null
            };
        case GET_ADMINS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                admins: action.payload
            };
        case GET_PUBLIC_ADMINS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                publicAdmins: action.payload
            };
        case GET_ADMINS_FAILURE:
        case GET_PUBLIC_ADMINS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        default:
            return state;
    }
};
