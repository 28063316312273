import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import ChartsAndGraphs from "./ChartsAndGraphs";
import { getClients } from "../../Store/Actions/clientActions";
import { getCoords } from "../../Store/Actions/coordinatorActions";
import { getEmployees } from "../../Store/Actions/employeesActions";

const Dashboard = () => {

  const dispatch = useDispatch();
  const clients = useSelector((state) => state?.clientsReducer?.clients);
  const coordinators = useSelector((state) => state?.coordinatorReducer?.coordinators);
  const employees = useSelector((state) => state?.employeesReducer?.employees);


  useEffect(() => {
    dispatch(getClients());
    dispatch(getCoords());
    dispatch(getEmployees());
  }, [])


  const active = useSelector((state) => state.sideActiveReducer.sideActive);
  // console.log(active)

  return (
    <div>
      <Navbar />
      <Sidebar />

      <div
        style={{
          marginLeft: "220px",
          padding: "40px",
          background: "#F2F2F2",

          minHeight: "90vh",
          position: "relative",
          top: "2px",
        }}
      >
        {/* {active === "Dashboard"
          ? <ChartsAndGraphs
            clients={clients}
            coordinators={coordinators}
            employees={employees}
          />
          : <Outlet />
        } */}
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
