// Get Employees
export const GET_EMPLOYEES_REQUEST = "GET_EMPLOYEES_REQUEST";

export const GET_EMPLOYEES_FAILURE = "GET_EMPLOYEES_FAILURE";

export const GET_EMPLOYEES_SUCCESS = "GET_EMPLOYEES_SUCCESS";

// Create Employees
export const CREATE_EMPLOYEES_REQUEST = "CREATE_EMPLOYEES_REQUEST";

export const CREATE_EMPLOYEES_FAILURE = "CREATE_EMPLOYEES_FAILURE";

export const CREATE_EMPLOYEES_SUCCESS = "CREATE_EMPLOYEES_SUCCESS";

// Delete Employee
export const DELETE_EMPLOYEE_REQUEST = "DELETE_EMPLOYEE_REQUEST";

export const DELETE_EMPLOYEE_FAILURE = "DELETE_EMPLOYEE_FAILURE";

export const DELETE_EMPLOYEE_SUCCESS = "DELETE_EMPLOYEE_SUCCESS";

// Edit Employee
export const EDIT_EMPLOYEE_REQUEST = "EDIT_EMPLOYEE_REQUEST";

export const EDIT_EMPLOYEE_FAILURE = "EDIT_EMPLOYEE_FAILURE";

export const EDIT_EMPLOYEE_SUCCESS = "EDIT_EMPLOYEE_SUCCESS";