
// Get Admins Constants

export const GET_ADMINS_REQUEST = 'GET_ADMINS_REQUEST';
export const GET_ADMINS_SUCCESS = 'GET_ADMINS_SUCCESS';
export const GET_ADMINS_FAILURE = 'GET_ADMINS_FAILURE';


// Get Public Admins Constants

export const GET_PUBLIC_ADMINS_REQUEST = 'GET_PUBLIC_ADMINS_REQUEST';
export const GET_PUBLIC_ADMINS_SUCCESS = 'GET_PUBLIC_ADMINS_SUCCESS';
export const GET_PUBLIC_ADMINS_FAILURE = 'GET_PUBLIC_ADMINS_FAILURE';
