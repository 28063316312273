import React, { useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import styles from "./style.module.css";

const LocationSearchInput = ({ onChange, address, setAddress }) => {
  const handleSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      onChange({
        lat: latLng.lat,
        lng: latLng?.lng,
        isCountry: results[0]?.types?.includes("country"),
        address: selectedAddress,
      });
    } catch (error) {
      console.error("Error: Select Place", error);
    }
  };

  const handleChange = (e) => setAddress(e);

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <input
            {...getInputProps({
              placeholder: "Search Places ...",
              className: `location-search-input ${styles.placeInput}`,
            })}
          />
          <div className="autocomplete-dropdown-container">
            {loading && <div className={styles.loading}>Loading...</div>}
            {suggestions.map((suggestion) => {
              const className = suggestion.active
                ? "suggestion-item--active"
                : "suggestion-item";
              // inline style for demonstration purpose
              const style = suggestion.active
                ? { backgroundColor: "#fafafa", cursor: "pointer" }
                : { backgroundColor: "#ffffff", cursor: "pointer" };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className: `suggestion-item--active ${styles.placeList}`,
                    style,
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default LocationSearchInput;
