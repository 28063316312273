import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { sideActiveAction } from "../../Store/Actions/sideActiveAction";
import Header from "./Header";
import styles from "./createcoordinator.module.css";
import Creating from "./Creating";
import Header2 from "./Header2";
import CreatePass from "./CreatePass";
import { BsPlusCircle } from "react-icons/bs";
import CustomButton from "../../Components/CustomButton";
import { createTask } from "../../Store/Actions/taskActions";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../Store/Actions/toastActions";
import GoogleMap from "./GoogleMap";
import AutoComplete from "./AutoComplete/index";

const CreateTask = () => {
  const [formValues, setFormValues] = useState({
    name: "",
    clientData: "",
    employeeData: "",
    lat: "",
    long: "",
    address: "",
    comment: "",
    description: "",
    date: "",
    time: "",
    endTime: "",
    // JobType: "simple",
    day: "",
    photo: null,
  });
  const [isCountry, setIsCountry] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const employeesState = useSelector((state) => state.employeesReducer);
  const taskState = useSelector((state) => state.tasksReducer);

  useEffect(() => {
    dispatch(sideActiveAction("Tasks"));
  }, []);

  // Handle inputs change
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePhotoChange = (event) => {
    const file = event.target.files[0];
    setFormValues((prevState) => ({
      ...prevState,
      photo: file,
    }));
  };
  const handlePlaceChange = (e) => {
    setFormValues({
      ...formValues,
      ["lat"]: e.lat,
      ["long"]: e.lng,
      ["address"]: e.address,
    });
    setIsCountry(e.isCountry);
  };

  const handleOnMapChaange = (data) => {
    setFormValues({
      ...formValues,
      ["lat"]: data.lat,
      ["long"]: data.lng,
    });
  };

  // Handle Upload Employee
  const handleUploadTask = () => {
    if (
      // !formValues?.name ||
      !formValues?.clientData ||
      !formValues?.employeeData ||
      !formValues?.lat ||
      !formValues?.long ||
      !formValues?.address ||
      // !formValues?.comment ||
      !formValues?.description ||
      !formValues?.date ||
      !formValues?.time
    ) {
      dispatch(showToast("Required Fields are Empty", "error"));
    } else {
      // Create Form Data for Sending
      const formData = new FormData();
      formData.append("name", formValues?.name);
      formData.append("clientId", formValues?.clientData.id);
      // formData.append("employId", formValues?.employeeData.id);
      formData.append("latitude", parseFloat(formValues?.lat));
      formData.append("longitude", parseFloat(formValues?.long));
      formData.append("address", formValues?.address);
      formData.append("description", formValues?.description);
      // formData.append("comment", formValues?.comment);
      formData.append("date", formValues?.date);
      formData.append("time", formValues?.time);
      // formData.append("endTime", formValues?.endTime);
      // formData.append(
      //   "type",
      //   formValues?.JobType.length > 0 ? formValues?.JobType : null
      // );
      // formData.append(
      //   "day",
      //   formValues?.day.length > 0 ? formValues?.day : null
      // );
      formData.append("photos", formValues?.photo);

      dispatch(createTask(formData, navigate, formValues));
    }
  };
  const handleSetAddress = (e) =>
    setFormValues({ ...formValues, ["address"]: e });

  return (
    <div>
      <section className={` mt-4 ${styles.mainDiv}`}>
        <Header />
        <Creating
          handleInputChange={handleInputChange}
          handlePhotoChange={handlePhotoChange}
          formValues={formValues}
          setFormValues={setFormValues}
        />
      </section>
      {/* <section className={` mt-4 ${styles.mainDiv}`}>
        <Header2 />
        <CreatePass
          handleInputChange={handleInputChange}
          formValues={formValues}
          setFormValues={setFormValues}
        />
      </section> */}
      <section className={` mt-4 ${styles.mainDiv}`}>
        {/* <GoogleMap formValues={formValues} setFormValues={setFormValues} /> */}
        <AutoComplete
          onChange={handlePlaceChange}
          address={formValues?.address}
          setAddress={handleSetAddress}
        />
        <GoogleMap
          onChange={handleOnMapChaange}
          lat={formValues?.lat}
          lng={formValues?.long}
          isCountry={isCountry}
        />
      </section>
      <div
        className={
          taskState?.isLoading
            ? "d-flex justify-content-center align-items-center mt-4"
            : "mt-4"
        }
      >
        {taskState?.isLoading ? (
          <Spinner style={{ color: "#6e528d" }} />
        ) : (
          <CustomButton
            width={"100%"}
            icon={<BsPlusCircle />}
            // label={"Upload Task"}
            label={"Cargar tarea"}
            onClick={handleUploadTask}
          />
        )}
      </div>
    </div>
  );
};

export default CreateTask;
