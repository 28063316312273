import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import manIcon from "../../Assets/manIcon.png";

const AnyReactComponent = ({ text }) => (
  <div
    style={{ zIndex: 222, position: "relative", bottom: "20px", right: "20px" }}
  >
    <img src={manIcon} width="50px" alt="manIcon " />
  </div>
);

const GoogleMap = ({ lat, lng }) => {
  const [getLatLong, setGetLatLong] = useState({});
  const defaultProps = {
    center: {
      lat: +lat, // using (+) unary operator
      lng: +lng, // using (+) unary operator
    },
    zoom: 11,
  };
  const defaultMapOptions = {
    disableDefaultUI: true,
  };
  
  return (
    <>
      {/* <Component /> */}
      <div style={{ height: "100%", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyCm2F_3p5FSR1pPKRznter_3QkM_LTbR6A" }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          options={defaultMapOptions}
          onClick={(e) => {
            setGetLatLong(e);
          }}
        >
          <AnyReactComponent
            lat={getLatLong.lat}
            lng={getLatLong.lng}
            text="My Marker"
          />
        </GoogleMapReact>
      </div>
    </>
  );
};

export default GoogleMap;
