import { combineReducers } from "redux";
import { loginReducer } from "./loginReducer";
import { sideActiveReducer } from "./sideActiveReducer";
import { getAdminsReducer } from "./getAdminsReducer"
import { employeesReducer } from "./employeesReducer"
import { clientsReducer } from "./clientsReducer";
import { coordinatorReducer } from "./coordinatorReducer";
import { activitiesReducer } from "./activitiesReducer";
import { tasksReducer } from "./tasksReducer";
import { toastReducer } from "./toastReducer";

const combineReducer = combineReducers({
  sideActiveReducer,
  loginReducer,
  getAdminsReducer,
  employeesReducer,
  clientsReducer,
  coordinatorReducer,
  activitiesReducer,
  tasksReducer,
  toastReducer
});

export default combineReducer;
