import API from "../../Config/Api";
import { EDIT_EMPLOYEE_SUCCESS } from "../Constants/employeesConts";
import { EDIT_EMPLOYEE_FAILURE } from "../Constants/employeesConts";
import {
    GET_EMPLOYEES_REQUEST,
    GET_EMPLOYEES_FAILURE,
    GET_EMPLOYEES_SUCCESS,
    CREATE_EMPLOYEES_REQUEST,
    CREATE_EMPLOYEES_FAILURE,
    CREATE_EMPLOYEES_SUCCESS,
    DELETE_EMPLOYEE_REQUEST,
    DELETE_EMPLOYEE_SUCCESS,
    DELETE_EMPLOYEE_FAILURE,
    EDIT_EMPLOYEE_REQUEST,
} from "../Constants/employeesConts";
import { showToast } from "./toastActions";

// Get Employees
export const getEmployees = () => async (dispatch) => {

    dispatch({
        type: GET_EMPLOYEES_REQUEST
    })
    try {
        const res = await API.post('/employs');
        // console.log(res)
        dispatch({
            type: GET_EMPLOYEES_SUCCESS,
            payload: res?.data?.data
        });
    } catch (error) {
        dispatch({
            type: GET_EMPLOYEES_FAILURE,
            payload: error
        });
    }
};


// Create Employees
export const createEmployee = (formData, navigate) => async (dispatch) => {

    dispatch({
        type: CREATE_EMPLOYEES_REQUEST
    })
    try {
        const response = await API.post('/employs-store', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        if (response.data.status === 200 || response.data.status === 201) {
            dispatch({
                type: CREATE_EMPLOYEES_SUCCESS,
                payload: response.data
            });
            dispatch(showToast("Employee Created Succesfully", "info"));
            navigate(-1)
        } else {
            const error = response.data;
            throw error;
        }

    } catch (error) {
        dispatch({
            type: CREATE_EMPLOYEES_FAILURE,
            payload: error.message
        });
        dispatch(showToast(error.message, "error"));
    }
};


// Delete Employee
export const deleteEmployee = (employeeId) => async (dispatch) => {
    dispatch({
        type: DELETE_EMPLOYEE_REQUEST,
    });

    try {
        const res = await API.post(`/employs-delete`, { employId: employeeId });
        dispatch({
            type: DELETE_EMPLOYEE_SUCCESS,
            payload: employeeId,
        });
        console.log(res)
        dispatch(showToast("Employee Deleted Successfully", "info"))
    } catch (error) {
        dispatch({
            type: DELETE_EMPLOYEE_FAILURE,
            payload: error,
        });
        dispatch(showToast(error.message, "error"));
    }
};


// Edit Employee
export const editEmployee = (formData, navigate) => async (dispatch) => {
    dispatch({
        type: EDIT_EMPLOYEE_REQUEST
    });
    try {
        const res = await API.post("/employs-update", formData);
        dispatch({
            type: EDIT_EMPLOYEE_SUCCESS,
            payload: res?.data
        });
        dispatch(showToast("Employee Edited Successfully", "info"));
        navigate(-1);
    } catch (error) {
        dispatch({
            type: EDIT_EMPLOYEE_FAILURE,
            payload: error
        });
        dispatch(showToast(error.message, "error"));
    }
};
