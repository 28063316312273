import axios from 'axios';

const API = axios.create({
  baseURL: 'http://136.244.113.52/api',
});

// Add request interceptor
API.interceptors.request.use(
  config => {
    // Add any headers, authentication data, or other configuration here
    return config;
  },
  error => {
    // Handle request error
    return error
  }
);

// Add response interceptor
API.interceptors.response.use(
  response => {
    // Handle successful response
    return response;
  },
  error => {
    // Handle response error
    return error
  }
);

export default API;
