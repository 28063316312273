import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import combineReducer from "./Reducers/combineReducer";
import {persistReducer, persistStore} from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
    key: 'sideActiveReducer',
    storage,
    whitelist:["sideActiveReducer"]
}

const persistedReducer = persistReducer(persistConfig, combineReducer);

const store = createStore(persistedReducer, {}, applyMiddleware(thunk));

export const persistor = persistStore(store);

export default store;