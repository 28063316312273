import React, { useState } from "react";
import styles from "./style.module.css";
import { useNavigate } from "react-router-dom";
import { AiOutlineDelete, AiOutlineEdit, AiOutlineEye } from "react-icons/ai";

const DataTable = ({
  tableData,
  headerData,
  selectedDate,
  selectedEmploy,
  selectedStatus,
  selectedProvince,
}) => {
  const [status, setStatus] = useState("active");
  const toggleStatus = () => {
    setStatus((curr) => (curr === "active" ? "Inactive" : "active"));
  };
  const navigate = useNavigate();

  return (
    <div className={styles.tableParent}>
      <table>
        <thead>
          <tr>
            <td>
              <input type="checkbox" className={styles.checkBox} />
            </td>
            {headerData?.map((value) => {
              return (
                <td key={value} className={styles.tHeading}>
                  {value}
                </td>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {tableData?.map((value) => {
            const activityDate = value?.task?.date;
            const activityStatus = value?.task?.status;
            const activityEmployId = value?.task?.employ?.id;
            const activeProvince = value?.task?.employ.province;
            console.log({ activeProvince });

            // Check if the selectedDate matches the activityDate, or if it is not set
            const isDateMatched =
              !selectedDate || selectedDate === activityDate;

            // Check if the selectedStatus matches the activityStatus, or if it is not set
            const isStatusMatched =
              selectedStatus && selectedStatus === activityStatus;

            // Check if the selectedEmploy matches the activityEmployId, or if it is not set
            const isEmployMatched =
              !selectedEmploy || selectedEmploy.id === activityEmployId;

            const isProvinceMatched =
              selectedProvince &&
              selectedProvince === activeProvince.toLowerCase();

            // Display the row if any of the filters match
            if (
              isDateMatched ||
              isStatusMatched ||
              isEmployMatched ||
              isProvinceMatched
            ) {
              const client = value?.task?.client;
              const employee = value?.task?.employ;

              return (
                <tr key={value?.id}>
                  <td>
                    <input type="checkbox" className={styles.checkBox} />
                  </td>
                  {employee ? (
                    <>
                      <td>{employee?.name}</td>
                      <td>{employee?.email}</td>
                      <td>{employee?.phone}</td>
                      <td>{employee?.address}</td>
                      <td>{activityDate}</td>
                    </>
                  ) : client ? (
                    <>
                      <td
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          navigate("/dashboard/accept-workers", {
                            state: value,
                          })
                        }
                      >
                        {client?.name}
                      </td>
                      <td>{client?.email}</td>
                      <td>{client?.phone}</td>
                      <td>{client?.address}</td>
                      <td>{activityDate}</td>
                    </>
                  ) : (
                    ""
                  )}
                  <td className={styles.actionTd}>
                    <span>
                      <AiOutlineDelete />
                    </span>
                    <span className="mx-2">
                      <AiOutlineEdit />
                    </span>
                    <span
                      onClick={() =>
                        navigate("/dashboard/accept-workers", { state: value })
                      }
                    >
                      <AiOutlineEye />
                    </span>
                  </td>
                </tr>
              );
            } else {
              // Exclude the row if any of the filters do not match
              return null;
            }
          })}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;
