import React from "react";
import DataTable from "./DataTable";
import { useLocation } from "react-router-dom";

function ProvinceRequest() {
  const { state } = useLocation();
  console.log("res", state);
  const header = [
    "Nombre",
    "Nombre del cliente",
    "Cliente Provincia",
    "Descripción",
    "Tiempo",
  ];

  return (
    <div>
      <h4>Detalles </h4>
      <DataTable headerData={header} tableData={state} />
    </div>
  );
}

export default ProvinceRequest;
