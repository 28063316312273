import React, { useState } from "react";
import { AiOutlineDelete, AiOutlineEdit, AiOutlineEye } from "react-icons/ai";
import styles from "./style.module.css";

import { useDispatch } from "react-redux";
import { deleteCoord } from "../../../Store/Actions/coordinatorActions";
import { useLocation, useNavigate } from "react-router-dom";
import { showToast } from "../../../Store/Actions/toastActions";
import API from "../../../Config/Api";

const DataTable = ({ tableData, headerData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDeleteCoord = (coordinatorId) => {
    dispatch(deleteCoord(coordinatorId));
  };

  return (
    <div className={styles.tableParent}>
      <table>
        <thead>
          <tr>
            {headerData?.map((value) => {
              return (
                <td
                  // style={{width:"150px"}}
                  key={value}
                  className={styles.tHeading}
                >
                  {value}
                </td>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {tableData.length > 0 ? (
            tableData?.map((value) => {
              return (
                <tr key={value?.id}>
                  <td>{value?.name}</td>
                  <td>{value?.clientName}</td>
                  <td>{value?.clientProvince}</td>
                  <td>{value?.description}</td>
                  <td>{value?.time}</td>

                  {/* <td>{updatedDateFormat}</td> */}

                  {/* <td className={styles.actionTd}>
                  <span onClick={() => handleDeleteCoord(value?.id)}>
                    <AiOutlineDelete />
                  </span>
                  <span className="mx-2">
                    <AiOutlineEdit
                      onClick={() =>
                        navigate("/dashboard/edit-coordinator", {
                          state: { value },
                        })
                      }
                    />
                  </span>
                  <span>
                    <AiOutlineEye
                      onClick={() =>
                        navigate("/dashboard/edit-coordinator", {
                          state: { value, viewOnly: true },
                        })
                      }
                    />
                  </span>
                </td> */}
                </tr>
              );
            })
          ) : (
            <tr>
              <td className="p-4 border text-center" colSpan={5}>
                <h5>No Data</h5>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;
