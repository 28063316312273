import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { sideActiveAction } from "../../Store/Actions/sideActiveAction";
import DataTable from "./DataTable";
import Header from "./Header";
import { getTasks } from "../../Store/Actions/taskActions";
import { BsPlus } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./style.css";

const Tasks = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tasksState = useSelector((state) => state.tasksReducer);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedEmploy, setSelectedEmploy] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");

  useEffect(() => {
    dispatch(sideActiveAction("Tasks"));
    dispatch(getTasks());
  }, []);

  // console.log(tasksState)

  const headerData = [
    "Task Name",
    "Client Name",
    "Employ Name",
    "Date",
    "Status",
    // "Created At",
    // "Updated At",
    "Action",
  ];

  const tasksArr = tasksState?.tasks;

  // const clientData = tasksArr?.filter((item) => item?.task?.client !== undefined)?.map((item) => item?.task?.client);
  // const employeeData = tasksArr?.filter((item) => item?.task?.employee !== undefined)?.map((item) => item?.task?.employee);
  // const combinedData = clientData?.concat(employeeData);
  // console.log("combined data", combinedData)

  // Convert Date
  const convertDate = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };

  return (
    <div>
      <Header
        setSelectedEmploy={setSelectedEmploy}
        setSelectedStatus={setSelectedStatus}
        setSelectedDate={setSelectedDate}
        setSelectedProvince={setSelectedProvince}
      />
      <section className={`Calender d-flex justify-content-center my-2`}>
        <Calendar
          onChange={(date) => {
            let formatetedDate = convertDate(date);
            setSelectedDate(formatetedDate);
          }}
          locale="es"
        />
      </section>
      <section
        className={
          tasksState.isLoading
            ? "d-flex justify-content-center align-items-center"
            : "mt-4"
        }
      >
        {tasksState.isLoading ? (
          <Spinner style={{ color: "#6E528D" }} />
        ) : (
          <DataTable
            selectedDate={selectedDate}
            headerData={headerData}
            tableData={tasksArr}
            selectedEmploy={selectedEmploy}
            selectedStatus={selectedStatus}
            selectedProvince={selectedProvince}
          />
        )}
      </section>
      <section
        onClick={() => navigate("/dashboard/create-task")}
        style={{
          position: "fixed",
          bottom: "10%",
          right: "3%",
          backgroundColor: "#6e528d",
          padding: "10px 13px",
          borderRadius: "50%",
          cursor: "pointer",
          zIndex: 9999,
        }}
      >
        <BsPlus
          style={{ fontSize: "30px", color: "#fff", fontWeight: "900" }}
        />
      </section>
    </div>
  );
};

export default Tasks;
