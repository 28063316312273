import React, { useState } from "react";
import {
  AiOutlineCheck,
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineEye,
} from "react-icons/ai";
import styles from "./style.module.css";
import Switch from "react-switch";
import CustomSelect from "../../../Components/CustomSelect";
import { useDispatch } from "react-redux";
import { deleteEmployee } from "../../../Store/Actions/employeesActions";
import { useNavigate } from "react-router-dom";

const DataTable = ({ tableData, headerData }) => {
  const [status, setStatus] = useState("active");
  const toggleStatus = () => {
    setStatus((curr) => (curr === "active" ? "Inactive" : "active"));
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Handle Delete Employee
  const handleDeleteEmployee = (employeeId) => {
    dispatch(deleteEmployee(employeeId));
  };
  return (
    <div className={styles.tableParent}>
      <table>
        <thead>
          <tr>
            <td>
              <input type="checkbox" className={styles.checkBox} />
            </td>
            {headerData?.map((value) => {
              return (
                <td
                  // style={{width:"150px"}}
                  key={value}
                  className={styles.tHeading}
                >
                  {value}
                </td>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {tableData?.map((value) => {
            // console.log(value)
            // const createdDate = new Date(value.created_at);
            // const createdDateFormat = createdDate.toDateString();

            // const updatedDate = new Date(value.updated_at);
            // const updatedDateFormat = updatedDate.toDateString();
            let { id } = value;
            console.log("value", id);
            return (
              <tr key={value?.id}>
                <td>
                  <input type="checkbox" className={styles.checkBox} />
                </td>
                <td
                  className="text-decoration-underline"
                  onClick={() =>
                    navigate("/dashboard/employe-task-details", {
                      state: { id },
                    })
                  }
                >
                  {value?.name}
                </td>
                {/* <td>{value?.email}</td> */}
                <td>{value?.address}</td>
                <td>{value?.phone}</td>
                <td>{value?.dateOfBirth}</td>
                {/* <td>{createdDateFormat}</td>
                <td>{updatedDateFormat}</td> */}
                <td>{value?.province}</td>
                <td className={styles.actionTd}>
                  <span onClick={() => handleDeleteEmployee(value?.id)}>
                    <AiOutlineDelete />
                  </span>
                  <span
                    onClick={() =>
                      navigate("/dashboard/edit-employee", { state: { value } })
                    }
                    className="mx-2"
                  >
                    <AiOutlineEdit />
                  </span>
                  <span
                    onClick={() =>
                      navigate("/dashboard/edit-employee", {
                        state: { value, viewOnly: true },
                      })
                    }
                  >
                    <AiOutlineEye />
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;
