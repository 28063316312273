import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Pages/Login";
import Services from "./Pages/Services";
import Clients from "./Pages/Clients";
import Appoinments from "./Pages/Appoinments";
import Dashboard from "./Pages/Dashboard";
import Coordinators from "./Pages/Coordinators";
import Employees from "./Pages/Employees";
import Workers from "./Pages/Workers";
import CreateCoordinator from "./Pages/Create Coordinators";
import CoordinatorDetails from "./Pages/CoordinatorDetails";
import CreateEmployee from "./Pages/Create Employee";
import EditEmployee from "./Pages/Edit Employee";
import AddClient from "./Pages/AddClients";
import AddServiceReciepts from "./Pages/Add Service Reciepts";
import AddWorkersReciepts from "./Pages/Add Workers Reciepts";
import EditClient from "./Pages/Edit Clients";
import EditCoordinator from "./Pages/Edit Coordinator";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { hideToast } from "./Store/Actions/toastActions"
import EmployeTaskDetai from "./Pages/EmployeTaskDetails";
import Tasks from "./Pages/Tasks";
import TaskDetails from "./Pages/Task Details";
import EmployeTaskTable from "./Pages/EmployeTaskTable";
import CreateTask from "./Pages/Create Task";
import ProvinceRequest from "./Pages/Coordinators/ProvinceRequest";


function App() {

  // Toast Component
  const toastState = useSelector((state) => state.toastReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (toastState.visible) {
      const options = {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      };
      if (toastState.type === "success") {
        toast.success(toastState.message, options);
      } else if (toastState.type === "error") {
        toast.error(toastState.message, options);
      } else if (toastState.type === "info") {
        toast.info(toastState.message, options);
      }
      dispatch(hideToast());
    }
  }, [toastState, dispatch]);

  return (
    <BrowserRouter>
      {/* <ImageCropper /> */}
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Login />} />

        <Route path="/dashboard" element={<Dashboard />}>
          {/* <Route path="point-of-interest" element={<PointOfInterest />} />
          <Route
            path="add-point-of-interest"
            element={<AddPointOfInterest />}
          />
          <Route
            path="add-another-point-of-interest"
            element={<AddAnotherPointOfInterest />}
          />
          <Route path="muncipality" element={<Muncipality />} />
          <Route path="add-muncipality" element={<AddMuncipality />} />
          <Route path="add-event" element={<AddEvent />} />
          <Route path="add-service" element={<AddService />} />
          <Route path="system" element={<System />} />
          <Route path="categories" element={<Categories />} />
          <Route path="statistics" element={<Statistics />} />
          <Route path="add-admin" element={<AddAdmin />} />
          <Route path="incidents" element={<Incidents />} />
          <Route path="languages" element={<Languages />} /> */}

          {/* <Route path="appoinments" element={<Appoinments />} />
          <Route path="services" element={<Services />} />
          <Route path="accept-service" element={<AddServiceReciepts />} /> */}
          <Route path="workers" element={<Workers />} />
          <Route path="accept-workers" element={<AddWorkersReciepts />} />
          <Route path="tasks" element={<Tasks />} />
          <Route path="create-task" element={<CreateTask />} />
          <Route path="task-details" element={<TaskDetails />} />
          <Route path="clients" element={<Clients />} />
          <Route path="create-client" element={<AddClient />} />
          <Route path="edit-client" element={<EditClient />} />
          <Route path="coordinators" element={<Coordinators />} />
          <Route path="request-province" element={<ProvinceRequest />} />
          <Route path="create-coordinator" element={<CreateCoordinator />} />
          <Route path="create-coordinator-2" element={<CoordinatorDetails />} />
          <Route path="edit-coordinator" element={<EditCoordinator />} />
          <Route path="employees" element={<Employees />} />
          <Route path="create-employee" element={<CreateEmployee />} />
          <Route path="edit-employee" element={<EditEmployee />} />
          {/* <Route path="employe-task-details" element={<EmployeTaskDetai />} /> */}
          <Route path="employe-task-details" element={<EmployeTaskTable />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
