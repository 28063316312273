import React, { useState } from "react";
import {
  AiOutlineCheck,
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineEye,
} from "react-icons/ai";
import styles from "./style.module.css";
import Switch from "react-switch";
import CustomSelect from "../../../Components/CustomSelect";
import Input from "../../../Components/Input";
import Heading from "../../../Components/Heading";
import { BsUpload } from "react-icons/bs";

const Creating = ({ formValues, setFormValues, handleInputChange }) => {
  return (
    <>
      <div className={`${styles.mainContainer}`}>
        {/* <div className={`${styles.imgGroup}`}>
          <div className={`${styles.fileUpload}`}>
            <BsUpload fontSize={"1.4rem"} color="grey" />
            <label className="mt-2">
              Upload
              <input type="file" className={`${styles.file}`} />
            </label>
          </div>
          <div className={`${styles.img1}`}></div>
          <div className={`${styles.img1}`}></div>
        </div> */}

        <div className={`mt-3 ${styles.inputGroup}`}>
          <div className={`${styles.input}`}>
            {/* <Input placeholder={"Client Name"} name="name" value={formValues.name} onChange={handleInputChange} /> */}
            <Input
              placeholder={"nombre del cliente"}
              name="name"
              value={formValues.name}
              onChange={handleInputChange}
            />
          </div>
          <div className={`${styles.input}`}>
            {/* <Input placeholder={"Email Address"} name="email" value={formValues.email} onChange={handleInputChange} /> */}
            <Input
              placeholder={"Dirección de correo electrónico"}
              name="email"
              value={formValues.email}
              onChange={handleInputChange}
            />
          </div>
          {/* <CustomSelect width={"48%"}>
            <option>Status</option>
            <option>
              <AiOutlineEye color="#fff" />
              View
            </option>
            <option>
              <AiOutlineEdit color="#fff" /> Edit
            </option>
            <option>
              <AiOutlineDelete color="#fff" /> Delete
            </option>
          </CustomSelect> */}
        </div>
        <div className={`mt-3 ${styles.inputGroup}`}>
          <div className={`${styles.input}`}>
            {/* <Input placeholder={"Phone Number"} name="phone" value={formValues.phone} onChange={handleInputChange} /> */}
            <Input
              placeholder={"Número de teléfono"}
              name="phone"
              value={formValues.phone}
              onChange={handleInputChange}
            />
          </div>
          <div className={`${styles.input}`}>
            {/* <Input placeholder={"Date of Birth"} name="dateOfBirth" value={formValues.dateOfBirth} onChange={handleInputChange} /> */}
            <Input
              placeholder={"Fecha de nacimiento"}
              name="dateOfBirth"
              value={formValues.dateOfBirth}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className={`mt-3 ${styles.inputGroup}`}>
          {/* <div className={`${styles.input}`}> */}
            {/* <Input placeholder={"Address"} name="address" value={formValues.address} onChange={handleInputChange} /> */}
            {/* <Input
              placeholder={"DIRECCIÓN"}
              name="address"
              value={formValues.address}
              onChange={handleInputChange}
            /> */}
          {/* </div> */}
          <div className={`${styles.input}`}>
            {/* <Input placeholder={"Address"} name="address" value={formValues.address} onChange={handleInputChange} /> */}
            <Input
              placeholder={"Provincia"}
              name="province"
              type={"text"}
              value={formValues.province}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Creating;
