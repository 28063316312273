// Get Client
export const GET_CLIENT_REQUEST = "GET_CLIENT_REQUEST";

export const GET_CLIENT_FAILURE = "GET_CLIENT_FAILURE";

export const GET_CLIENT_SUCCESS = "GET_CLIENT_SUCCESS";

// Create Client
export const CREATE_CLIENT_REQUEST = "CREATE_CLIENT_REQUEST";

export const CREATE_CLIENT_FAILURE = "CREATE_CLIENT_FAILURE";

export const CREATE_CLIENT_SUCCESS = "CREATE_CLIENT_SUCCESS";

// Delete Client
export const DELETE_CLIENT_REQUEST = "DELETE_CLIENT_REQUEST";

export const DELETE_CLIENT_FAILURE = "DELETE_CLIENT_FAILURE";

export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS";

// Edit Client
export const EDIT_CLIENT_REQUEST = "EDIT_CLIENT_REQUEST";

export const EDIT_CLIENT_FAILURE = "EDIT_CLIENT_FAILURE";

export const EDIT_CLIENT_SUCCESS = "EDIT_CLIENT_SUCCESS";