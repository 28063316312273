import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { sideActiveAction } from "../../Store/Actions/sideActiveAction";
import DataTable from "./DataTable";
import Header from "./Header";
import { getActivities } from "../../Store/Actions/activitiesActions";

const Workers = () => {
  const dispatch = useDispatch();
  const activitiesState = useSelector((state) => state.activitiesReducer);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedEmploy, setSelectedEmploy] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");

  useEffect(() => {
    dispatch(sideActiveAction("Workers Mapping"));
    dispatch(getActivities());
  }, []);

  // console.log(activitiesState)

  const headerData = [
    "Employ Name",
    "Email",
    "Phone",
    "Address",
    "Date",
    // "Created At",
    // "Updated At",
    "Action",
  ];

  const activitiesArr = activitiesState?.activities;

  // const clientData = activitiesArr?.filter((item) => item?.task?.client !== undefined)?.map((item) => item?.task?.client);
  // const employeeData = activitiesArr?.filter((item) => item?.task?.employee !== undefined)?.map((item) => item?.task?.employee);
  // const combinedData = clientData?.concat(employeeData);
  // console.log("combined data", combinedData)

  return (
    <div>
      <Header
        setSelectedDate={setSelectedDate}
        setSelectedEmploy={setSelectedEmploy}
        setSelectedStatus={setSelectedStatus}
        setSelectedProvince={setSelectedProvince}
      />
      <section
        className={
          activitiesState.isLoading
            ? "d-flex justify-content-center align-items-center"
            : "mt-4"
        }
      >
        {activitiesState.isLoading ? (
          <Spinner style={{ color: "#6E528D" }} />
        ) : (
          <DataTable
            selectedDate={selectedDate}
            headerData={headerData}
            tableData={activitiesArr}
            selectedEmploy={selectedEmploy}
            selectedStatus={selectedStatus}
            selectedProvince={selectedProvince}
          />
        )}
      </section>
    </div>
  );
};

export default Workers;
