import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { sideActiveAction } from "../../Store/Actions/sideActiveAction";
import DataTable from "./DataTable";
import Header from "./Header";
import { getEmployees } from "../../Store/Actions/employeesActions"

const Employees = () => {

  const dispatch = useDispatch();
  const employeeState = useSelector((state) => state.employeesReducer);

  useEffect(() => {
    dispatch(sideActiveAction("Employees"));
    dispatch(getEmployees());
  }, []);
  console.log(employeeState)

  const headerData = [
    "Nombre de empleado", //"Employee Name",
    "DIRECCIÓN", // "Address",
    "Teléfono",//"Phone",
    "Fecha de nacimiento", //"Date of Birth",
    "Provincia", //"Status",
    "Comportamiento", //"Actions",
  ];
  const employeesArr = employeeState?.employees;

  return (
    <div>
      <Header />
      <section
        className={
          employeeState.isLoading
            ? "d-flex justify-content-center align-items-center"
            : "mt-4"
        }
      >
        {employeeState.isLoading ? (
          <Spinner style={{ color: "#6E528D" }} />
        ) : (
          <DataTable headerData={headerData} tableData={employeesArr} />
        )}
      </section>
    </div>
  );
};

export default Employees;
