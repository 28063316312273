import React, { useEffect } from "react";
import { BsPlusCircle } from "react-icons/bs";
import { useDispatch } from "react-redux";
import CustomButton from "../../Components/CustomButton";
import { tableData } from "../../locals/DummyData";
import { sideActiveAction } from "../../Store/Actions/sideActiveAction";
import GoogleMap from "../../Components/GoogleMap"

import styles from "./addWorkersRecipts.module.css";
import ClientDetails from "./Client Details";

import EmployeeDetails from "./Employee Detail";
import ServiceTime from "./Service Time";
import { useLocation } from "react-router-dom";
const AddWorkersReciepts = () => {

  const dispatch = useDispatch();
  const { state } = useLocation();
  console.log(state)

  const clientDetails = state?.task?.client;
  const employeeDetails = state?.task?.employ;

  // console.log("client >>>>>", clientDetails);
  // console.log("employee >>>>>", employeeDetails);

  useEffect(() => {
    dispatch(sideActiveAction("Workers Mapping"));
  }, []);

  const serviceTimeObj = {
    startTime: state?.startTime,
    endTime: state?.endTime
  }

  return (
    <div>
      {
        clientDetails &&
        <>
          <section className={` mt-4 ${styles.mainDiv}`}>
            <ClientDetails clientDetails={state} />
            <ServiceTime serviceTime={serviceTimeObj} />
          </section>
        </>
      }
      {
        employeeDetails &&
        <>
          <section className={` mt-4 ${styles.mainDiv}`}>
            <EmployeeDetails employeeDetails={state} />
            <ServiceTime serviceTime={serviceTimeObj} />
          </section>
        </>
      }
      <section className={` mt-4 ${styles.mainDiv} ${styles.mapBox}`}>
        <GoogleMap
          lat={state?.task?.latitude}
          lng={state?.task?.longitude}
        />
      </section>
      {/* <div className="mt-4">
        <CustomButton
          width={"100%"}
          icon={<BsPlusCircle />}
          label={"Accept Worker Receipt"}
        />
      </div> */}
    </div>
  );
};

export default AddWorkersReciepts;
