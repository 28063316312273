import {
    GET_COORDS_REQUEST,
    GET_COORDS_FAILURE,
    GET_COORDS_SUCCESS,
    CREATE_COORD_REQUEST,
    CREATE_COORD_FAILURE,
    CREATE_COORD_SUCCESS,
    DELETE_COORD_REQUEST,
    DELETE_COORD_FAILURE,
    DELETE_COORD_SUCCESS,
    EDIT_COORD_FAILURE,
    EDIT_COORD_REQUEST,
    EDIT_COORD_SUCCESS
} from "../Constants/coordinatorConsts";

const initialState = {
    coordinators: [],
    createCoordRes: "",
    // deleteCoordRes: "",
    editCoordRes: "",
    isLoading: false,
    error: null
};

export const coordinatorReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_COORDS_REQUEST:
        case CREATE_COORD_REQUEST:
        case DELETE_COORD_REQUEST:
        case EDIT_COORD_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case GET_COORDS_FAILURE:
        case CREATE_COORD_FAILURE:
        case DELETE_COORD_FAILURE:
        case EDIT_COORD_FAILURE:
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };
        case GET_COORDS_SUCCESS:
            return {
                ...state,
                coordinators: action.payload,
                isLoading: false
            };
        case CREATE_COORD_SUCCESS:
            return {
                ...state,
                createCoordRes: action.payload,
                isLoading: false
            };
        case DELETE_COORD_SUCCESS:
            const updatedcoordinators = state.coordinators.filter(
                (coord) => coord.id !== action.payload
            );
            return {
                ...state,
                coordinators: updatedcoordinators,
                isLoading: false,
            };
        case EDIT_COORD_SUCCESS:
            return {
                ...state,
                editCoordRes: action.payload,
                isLoading: false,
            };
        default:
            return state;
    }
};
