import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { sideActiveAction } from "../../Store/Actions/sideActiveAction";
import DataTable from "./DataTable";
import Header from "./Header";
import { getCoords } from "../../Store/Actions/coordinatorActions";

const Coordinators = () => {
  const dispatch = useDispatch();
  const coordinatorStates = useSelector((state) => state.coordinatorReducer);
  useEffect(() => {

    dispatch(sideActiveAction("Coordinators"));
    dispatch(getCoords());

  }, [coordinatorStates?.createCoordRes, coordinatorStates?.editCoordRes]);

  const headerData = [
    "Nombre del coordinador", //"Coordinator Name",
    "Ciudad", // "City",
    "Provincia",//"Province",
    "Creado en", //"Created At",
    "Comportamiento", //"Actions",
  ];
  
  const coordinatorArr = coordinatorStates?.coordinators;

  return (
    <div>
      <Header />
      <section
        className={
          coordinatorStates.isLoading
            ? "d-flex justify-content-center align-items-center"
            : "mt-4"
        }
      >
        {coordinatorStates.isLoading ? (
          <Spinner style={{ color: "#6E528D" }} />
        ) : (
          <DataTable headerData={headerData} tableData={coordinatorArr} />
        )}
      </section>
    </div>
  );
};

export default Coordinators;
