import React from "react";
import styles from "./style.module.css";

import { FaRegListAlt, FaRegUser } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { sideActiveAction } from "../../Store/Actions/sideActiveAction";
import { MdDashboard } from "react-icons/md";
import { HiOutlineUserGroup, HiOutlineUsers } from "react-icons/hi";
import { BsCalendar2Check } from "react-icons/bs";
import { GrMapLocation, GrTasks } from "react-icons/gr";

const arr = [
  {
    linkName: "Tasks",
    linkNameSpanish: "Tareas",
    icon: GrTasks,
    route: "/dashboard/tasks",
    id: 7,
  },
  // {
  //   linkName: "Dashboard",
  //   linkNameSpanish: "Panel",
  //   icon: MdDashboard,
  //   route: "/dashboard",
  //   id: 1,
  // },
  // {
  //   linkName: "Appoinments",
  //   icon: FaRegListAlt,
  //   route: "/dashboard/appoinments",
  //   id: 2,
  // },
  {
    linkName: "Clients",
    linkNameSpanish: "Clientas",
    icon: HiOutlineUserGroup,
    route: "/dashboard/clients",
    id: 3,
  },
  {
    linkName: "Coordinators",
    linkNameSpanish: "Coordinadoras",
    icon: FaRegUser,
    route: "/dashboard/coordinators",
    id: 4,
  },
  {
    linkName: "Employees",
    linkNameSpanish: "Empleadas",
    icon: HiOutlineUsers,
    route: "/dashboard/employees",
    id: 5,
  },
  {
    linkName: "Workers Mapping",
    linkNameSpanish: "Mapeo de trabajadoras",
    icon: GrMapLocation,
    route: "/dashboard/workers",
    id: 6,
  },
  // {
  //   linkName: "Services Receipts",
  //   icon: BsCalendar2Check,
  //   route: "/dashboard/services",
  //   id: 7,
  // },
  // {
  //   linkName: "Points Of interest",
  //   icon: FaPinterestP,
  //   route: "/dashboard/point-of-interest",
  //   id: 3,
  // },
  // {
  //   linkName: "Muncipalitty",
  //   icon: MdOutlineLocationCity,
  //   route: "/dashboard/muncipality",
  //   id: 4,
  // },

  // {
  //   linkName: "Services",
  //   icon: AiOutlineAppstore,
  //   route: "/dashboard/services",
  //   id: 5,
  // },
  // {
  //   linkName: "Statistics",
  //   icon: SlChart,
  //   route: "/dashboard/statistics",
  //   id: 6,
  // },
  // {
  //   linkName: "Categories",
  //   icon: BiCoinStack,
  //   route: "/dashboard/categories",
  //   id: 7,
  // },
  // {
  //   linkName: "System",
  //   icon: RiComputerLine,
  //   route: "/dashboard/system",
  //   id: 8,
  // },

  // {
  //   linkName: "Incidents",
  //   icon: ImWarning,
  //   route: "/dashboard/incidents",
  //   id: 10,
  // },
  // {
  //   linkName: "Languages",
  //   icon: MdLanguage,
  //   route: "/dashboard/languages",
  //   id: 11,
  // },
];

const Sidebar = () => {
  const active = useSelector((state) => state.sideActiveReducer.sideActive);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sideActive = (name, route) => {
    // console.log("hello");
    // console.log(name);
    dispatch(sideActiveAction(name));
    navigate(route);
  };
  return (
    <div className={styles.sidebar}>
      {arr.map((value) => {
        return (
          <section
            className={styles.sidebarLinksBox}
            onClick={() => sideActive(value.linkName, value.route)}
            key={value.id}
          >
            <span
              className={styles.linkLine}
              style={{
                background: active === value.linkName ? "#6E528D" : "",
                transition:
                  active === value.linkName ? "0.5s ease-in" : "0.5s ease-out",
              }}
            ></span>
            <p className={styles.sideLinkText}>
              <span>
                <value.icon
                  className={styles.icon}
                  style={{
                    color: active === value.linkName ? "#6E528D" : "gray",
                    transition:
                      active === value.linkName
                        ? "0.5s ease-in"
                        : "0.5s ease-out",
                  }}
                />
              </span>
              <span
                className={styles.text}
                style={{
                  color: active === value.linkName ? "#6E528D" : "#212529",
                  transition:
                    active === value.linkName
                      ? "0.5s ease-in"
                      : "0.5s ease-out",
                }}
              >
                {value.linkNameSpanish}
              </span>
            </p>
          </section>
        );
      })}
    </div>
  );
};

export default Sidebar;
