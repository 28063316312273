import React, { useState } from "react";

import styles from "./style.module.css";

import { GrMapLocation } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { AiOutlineDelete, AiOutlineEdit, AiOutlineEye } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { deleteTask } from "../../../Store/Actions/taskActions";

const DataTable = ({
  tableData,
  headerData,
  selectedDate,
  selectedStatus,
  selectedEmploy,
  selectedProvince,
}) => {
  const [status, setStatus] = useState("active");
  const toggleStatus = () => {
    setStatus((curr) => (curr === "active" ? "Inactive" : "active"));
  };

  const dispatch = useDispatch();
  const handleDeleteTask = (taskId) => {
    dispatch(deleteTask(taskId));
  };

  const navigate = useNavigate();

  // Filter the tableData based on selected filters
  const filteredData = tableData?.filter((value) => {
    const province = value?.employ?.province;
    const taskDate = value?.date;
    const taskStatus = value?.status;
    const taskEmployId = value?.employ?.id;

    // Check if the value matches the selectedDate if it's selected
    if (selectedDate && taskDate !== selectedDate) {
      return false;
    }

    // Check if the value matches the selectedStatus if it's selected
    if (selectedStatus && taskStatus !== selectedStatus) {
      return false;
    }

    // Check if the value matches the selectedEmploy if it's selected
    if (selectedEmploy && taskEmployId !== selectedEmploy.id) {
      return false;
    }

    // Check if the value matches the selectedEmploy if it's selected
    if (selectedProvince && province?.toLowerCase() !== selectedProvince) {
      return false;
    }

    return true;
  });

  return (
    <div className={styles.tableParent}>
      <table>
        <thead>
          <tr>
            <td>
              <input type="checkbox" className={styles.checkBox} />
            </td>
            {headerData?.map((value) => {
              return (
                <td key={value} className={styles.tHeading}>
                  {value}
                </td>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {filteredData?.map((value) => {
            const taskDate = value?.date;
            const taskStatus = value?.status;
            const taskEmployId = value?.employ?.id;

            const client = value?.client;
            const employee = value?.employ;

            return (
              <tr key={value?.id}>
                <td>
                  <input type="checkbox" className={styles.checkBox} />
                </td>
                <td>{value?.name}</td>
                <td>{client?.name}</td>
                <td>{employee?.name}</td>
                <td>{taskDate}</td>
                <td>{taskStatus ? taskStatus : "Pending"}</td>
                <td className={styles.actionTd}>
                  <span
                    className="me-2"
                    onClick={() => handleDeleteTask(value?.id)}
                  >
                    <AiOutlineDelete />
                  </span>
                  <span
                    className="me-2"
                    onClick={() =>
                      navigate("/dashboard/task-details", { state: value })
                    }
                  >
                    <AiOutlineEye />
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;
