import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { sideActiveAction } from "../../Store/Actions/sideActiveAction";
import Header from "./Header";
import styles from "./createcoordinator.module.css";
import Creating from "./Creating";
import Header2 from "./Header2";
import CreatePass from "./CreatePass";
import { BsPlusCircle } from "react-icons/bs";
import CustomButton from "../../Components/CustomButton";
import { createEmployee, editEmployee } from "../../Store/Actions/employeesActions";
import { useLocation, useNavigate } from "react-router-dom";
import { showToast } from "../../Store/Actions/toastActions";
const EditEmployee = () => {

  const { state } = useLocation();
  console.log(state)

  const [formValues, setFormValues] = useState({
    name: state?.value?.name,
    phone: state?.value?.phone,
    nif: state?.value?.nif,
    dateOfBirth: state?.value?.dateOfBirth,
    gender: state?.value?.gender,
    address: state?.value?.address,
    password: state?.value?.password,
    cnfrmPass: state?.value?.password,
    photo: state?.value?.photo,
    email: state?.value?.email,
    city: state?.value?.city,
    province: state?.value?.province,
  });
  // console.log(formValues)

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const employeesState = useSelector((state) => state.employeesReducer);
  console.log(employeesState);

  useEffect(() => {
    dispatch(sideActiveAction("Employees"));
  }, []);

  // Handle inputs change
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePhotoChange = (event) => {
    const file = event.target.files[0];
    setFormValues((prevState) => ({
      ...prevState,
      photo: file,
    }));
  };

  // Handle Upload Employee
  const handleUploadEmployee = () => {
    if (
      !formValues?.name ||
      !formValues?.phone ||
      !formValues?.nif ||
      !formValues?.dateOfBirth ||
      !formValues?.gender ||
      !formValues?.address ||
      !formValues?.password ||
      !formValues?.cnfrmPass ||
      !formValues?.email ||
      !formValues?.city ||
      !formValues?.province
    ) {
      dispatch(showToast("Required Fields are Empty", "error"));
    }
    else {
      if (formValues?.password === formValues?.cnfrmPass) {

        // Create Form Data for Sending
        const formData = new FormData();
        formData.append("name", formValues?.name);
        formData.append("phone", formValues?.phone);
        formData.append("nif", formValues?.nif);
        formData.append("dateOfBirth", formValues?.dateOfBirth);
        formData.append("gender", formValues?.gender);
        formData.append("address", formValues?.address);
        formData.append("password", formValues?.password);
        formData.append("photo", formValues?.photo);
        formData.append("email", formValues?.email);
        formData.append("city", formValues?.city);
        formData.append("province", formValues?.province);
        formData.append("employId", state?.value?.id);

        dispatch(editEmployee(formData, navigate));
      }
      else {
        dispatch(showToast("Password and Confirm Password Should be Equal", "error"));
      }

    }
  }

  return (

    <div>
      <section className={` mt-4 ${styles.mainDiv}`}>
        <Header />
        <Creating
          viewOnly={state?.viewOnly}
          handleInputChange={handleInputChange}
          handlePhotoChange={handlePhotoChange}
          formValues={formValues}
          setFormValues={setFormValues}
        />
      </section>
      {
        state?.viewOnly
          ? ""
          : <section className={` mt-4 ${styles.mainDiv}`}>
            <Header2 />
            <CreatePass
              handleInputChange={handleInputChange}
              formValues={formValues}
              setFormValues={setFormValues}
            />
          </section>
      }
      <div className={
        employeesState?.isLoading
          ? "d-flex justify-content-center align-items-center mt-4"
          : "mt-4"
      }>
        {
          employeesState?.isLoading
            ? <Spinner style={{ color: "#6e528d" }} />
            : <CustomButton
              width={"100%"}
              icon={<BsPlusCircle />}
              // label={"Upload Employee"}
              label={"Subir empleada"}
              onClick={handleUploadEmployee}
              disabled={state?.viewOnly}
            />
        }
      </div>
    </div>
  );
};

export default EditEmployee;
