import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { sideActiveAction } from "../../Store/Actions/sideActiveAction";
import Header from "./Header";
import styles from "./addclients.module.css";
import Creating from "./Creating";
import Header2 from "./Header2";
import CreatePass from "./CreatePass";
import { BsPlusCircle } from "react-icons/bs";
import CustomButton from "../../Components/CustomButton";
import GoogleMap from "../Create Task/GoogleMap";
import AutoComplete from "../Create Task/AutoComplete";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { editClient } from "../../Store/Actions/clientActions";
import { showToast } from "../../Store/Actions/toastActions";

const EditClient = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const clientState = useSelector((state) => state.clientsReducer);
  console.log(clientState);
  // console.log("state>>>>", state)

  const [formValues, setFormValues] = useState({
    name: state?.value?.name,
    phone: state?.value?.phone,
    email: state?.value?.email,
    dateOfBirth: state?.value?.dateOfBirth,
    latitude: state?.value?.latitude,
    longitude: state?.value?.longitude,
    address: state?.value?.address,
    password: state?.value?.password,
    cnfrmPass: state?.value?.password,
    province: state?.value?.province || "",
  });
  const [isCountry, setIsCountry] = useState(false);

  // console.log(formValues)

  useEffect(() => {
    dispatch(sideActiveAction("Clients"));
  }, []);

  // Handle inputs change
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handlePlaceChange = (e) => {
    setFormValues({
      ...formValues,
      ["lat"]: e.lat,
      ["long"]: e.lng,
      ["address"]: e.address,
    });
    setIsCountry(e.isCountry);
  };

  const handleOnMapChaange = (data) => {
    setFormValues({
      ...formValues,
      ["lat"]: data.lat,
      ["long"]: data.lng,
    });
  };
  const handleSetAddress = (e) =>
    setFormValues({ ...formValues, ["address"]: e });

  const handleUploadClient = () => {
    if (
      !formValues?.name ||
      !formValues?.phone ||
      !formValues?.email ||
      !formValues?.dateOfBirth ||
      !formValues?.latitude ||
      !formValues?.longitude ||
      !formValues?.address ||
      !formValues?.password ||
      !formValues?.cnfrmPass ||
      !formValues?.province
    ) {
      dispatch(showToast("Required Fields are Empty", "error"));
    } else {
      if (formValues?.password === formValues?.cnfrmPass) {
        const data = {
          name: formValues?.name,
          phone: formValues?.phone,
          email: formValues?.email,
          dateOfBirth: formValues?.dateOfBirth,
          latitude: formValues?.latitude,
          longitude: formValues?.longitude,
          address: formValues?.address,
          password: formValues?.password,
          clientId: state?.value?.id,
          province: formValues?.province,
        };

        dispatch(editClient(data, navigate));
      } else {
        dispatch(
          showToast("Password and Confirm Password Should be Equal", "error")
        );
      }
    }
  };

  return (
    <div>
      <section className={` mt-4 ${styles.mainDiv}`}>
        <Header />
        <Creating
          handleInputChange={handleInputChange}
          formValues={formValues}
          setFormValues={setFormValues}
        />
      </section>
      <section className={`mt-4 ${styles.mainDiv}`}>
        {/* <GoogleMap formValues={formValues} setFormValues={setFormValues} /> */}
        <AutoComplete
          onChange={handlePlaceChange}
          address={formValues?.address}
          setAddress={handleSetAddress}
        />
        <GoogleMap
          onChange={handleOnMapChaange}
          lat={formValues?.lat}
          lng={formValues?.long}
          isCountry={isCountry}
        />
      </section>
      {state?.viewOnly ? (
        ""
      ) : (
        <section className={` mt-4 ${styles.mainDiv}`}>
          <Header2 />
          <CreatePass
            handleInputChange={handleInputChange}
            formValues={formValues}
            setFormValues={setFormValues}
          />
        </section>
      )}
      <div
        className={
          clientState?.isLoading
            ? "d-flex justify-content-center align-items-center mt-4"
            : "mt-4"
        }
      >
        {clientState?.isLoading ? (
          <Spinner style={{ color: "#6e528d" }} />
        ) : (
          <CustomButton
            width={"100%"}
            icon={<BsPlusCircle />}
            // label={"Upload Client"}
            label={"Cargar cliente"}
            onClick={handleUploadClient}
          />
        )}
      </div>
    </div>
  );
};

export default EditClient;
