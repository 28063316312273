import { REGISTER } from "redux-persist";
import API from "../../Config/Api";
import {
  GET_TASK_FAILURE,
  GET_TASK_REQUEST,
  GET_TASK_SUCCESS,
  GET_EMPLOYEE_TASK_FAILURE,
  GET_EMPLOYEE_TASK_REQUEST,
  GET_EMPLOYEE_TASK_SUCCESS,
  DELETE_TASK_FAILURE,
  DELETE_TASK_REQUEST,
  DELETE_TASK_SUCCESS,
  CREATE_TASK_FAILURE,
  CREATE_TASK_SUCCESS,
  CREATE_TASK_REQUEST,
} from "../Constants/taskConsts";
import { showToast } from "./toastActions";

// Get Tasks
export const getTasks = () => async (dispatch) => {
  dispatch({
    type: GET_TASK_REQUEST,
  });
  try {
    const res = await API.post("/tasks");
    // console.log(res)
    dispatch({
      type: GET_TASK_SUCCESS,
      payload: res?.data?.data,
    });
  } catch (error) {
    dispatch({
      type: GET_TASK_FAILURE,
      payload: error,
    });
  }
};

// Create Task
export const createTask =
  (formData, navigate, formValues) => async (dispatch) => {
    dispatch({
      type: CREATE_TASK_REQUEST,
    });
    try {
      const response = await API.post("/requests-store", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.data.status === 200 || response.data.status === 201) {
        const storeTask = await API.post("/tasks-store", {
          employId: formValues?.employeeData?.id,
          reqId: response?.data?.data?.id,
        });

        dispatch({
          type: CREATE_TASK_SUCCESS,
          payload: storeTask.data,
        });
        dispatch(showToast("Task Created Succesfully", "info"));
        navigate(-1);
      } else {
        const error = response.data;
        throw error;
      }
    } catch (error) {
      dispatch({
        type: CREATE_TASK_FAILURE,
        payload: error.message,
      });
      dispatch(showToast(error.message, "error"));
    }
  };

// Delete Tasks
export const deleteTask = (taskId) => async (dispatch) => {
  dispatch({
    type: DELETE_TASK_REQUEST,
  });

  try {
    const res = await API.post(`/tasks-delete`, { taskId: taskId });
    dispatch({
      type: DELETE_TASK_SUCCESS,
      payload: taskId,
    });
    // console.log(res)
    dispatch(showToast("Task Deleted Successfully", "info"));
  } catch (error) {
    dispatch({
      type: DELETE_TASK_FAILURE,
      payload: error,
    });
    dispatch(showToast(error.message, "error"));
  }
};

// Get Employee Tasks
export const getEmployeeTasks = (employId) => async (dispatch) => {
  dispatch({
    type: GET_EMPLOYEE_TASK_REQUEST,
  });
  try {
    const res = await API.post("/get-employ-tasks", {
      employId: employId,
    });
    // console.log(res)
    dispatch({
      type: GET_EMPLOYEE_TASK_SUCCESS,
      payload: res?.data?.data,
    });
  } catch (error) {
    dispatch({
      type: GET_EMPLOYEE_TASK_FAILURE,
      payload: error,
    });
  }
};
