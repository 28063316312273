// Get Coordinaotrs
export const GET_COORDS_REQUEST = "GET_COORDS_REQUEST";

export const GET_COORDS_FAILURE = "GET_COORDS_FAILURE";

export const GET_COORDS_SUCCESS = "GET_COORDS_SUCCESS";

// Create Coordinaotrs
export const CREATE_COORD_REQUEST = "CREATE_COORD_REQUEST";

export const CREATE_COORD_FAILURE = "CREATE_COORD_FAILURE";

export const CREATE_COORD_SUCCESS = "CREATE_COORD_SUCCESS";

// Delete Coordinaotrs
export const DELETE_COORD_REQUEST = "DELETE_COORD_REQUEST";

export const DELETE_COORD_FAILURE = "DELETE_COORD_FAILURE";

export const DELETE_COORD_SUCCESS = "DELETE_COORD_SUCCESS";

// Edit Coordinaotrs
export const EDIT_COORD_REQUEST = "EDIT_COORD_REQUEST";

export const EDIT_COORD_FAILURE = "EDIT_COORD_FAILURE";

export const EDIT_COORD_SUCCESS = "EDIT_COORD_SUCCESS";