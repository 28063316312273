import React from "react";
import styles from "./style.module.css";
const CustomButton = ({ label, width, onClick, icon, disabled, className }) => {
  return (
    <button
      disabled={disabled && disabled}
      className={`${styles.btn} ${className}`}
      onClick={onClick && onClick}
      style={{ width: width && width }}
    >
      {icon && (
        <span
          style={{
            position: "relative",
            bottom: "1px",
            right: 5,
          }}
        >
          {icon}
        </span>
      )}
      {label}
    </button>
  );
};

export default CustomButton;
