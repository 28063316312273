import { useEffect, useState } from "react";
import styles from "./style.module.css";
import { BsUpload } from "react-icons/bs";
import CustomSelect from "../../../Components/CustomSelect";
import Input from "../../../Components/Input";
import API from "../../../Config/Api";
import Select from "react-select";
import { useSelector } from "react-redux";

const CreateTaskForm = ({
  formValues,
  setFormValues,
  handleInputChange,
  handlePhotoChange,
}) => {
  const [displayImgUrl, setDisplayImgUrl] = useState("");
  const [employ, setEmploy] = useState([]);
  const [client, setClient] = useState([]);

  const jopTypeOptions = [
    {
      value: "simple",
      label: "Simple",
    },
    {
      value: "recurrent",
      label: "Recurrent",
    },
  ];

  const daysArr = [
    { value: 0, label: "Sunday" },
    { value: 1, label: "Monday" },
    { value: 2, label: "Tuesday" },
    { value: 3, label: "Wednesday" },
    { value: 4, label: "Thursday" },
    { value: 5, label: "Friday" },
    { value: 6, label: "Saturday" },
  ];

  const employeesState = useSelector((state) => state.employeesReducer);
  const clientsState = useSelector((state) => state.clientsReducer);

  useEffect(() => {
    // Employ
    const formattedEmploy = employeesState?.employees?.map((employObj) => ({
      value: employObj,
      label: employObj.name,
    }));
    setEmploy(formattedEmploy);

    // Client
    const formattedClient = clientsState?.clients?.map((clientObj) => ({
      value: clientObj,
      label: clientObj.name,
    }));
    setClient(formattedClient);
  }, [clientsState?.clients, employeesState?.employees]);

  useEffect(() => {
    const fetchData = async () => {
      if (formValues?.photo) {
        try {
          const formData = new FormData();
          formData.append("image", formValues.photo);
          const response = await API.post("/get-path", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          setDisplayImgUrl(response?.data?.data);
        } catch (error) {
          console.log(error);
        }
      }
    };
    fetchData();
  }, [formValues?.photo]);

  // Handle Select Employ
  const handleSelectEmploy = (selectedOption) => {
    setFormValues({ ...formValues, employeeData: selectedOption.value });
  };

  // Handle Select Client
  const handleSelectClient = (selectedOption) => {
    setFormValues({ ...formValues, clientData: selectedOption.value });
  };

  // Handle Select JobType
  const handleSelectJobType = (selectedOption) => {
    setFormValues({ ...formValues, JobType: selectedOption.value });
  };

  // Handle Select Days
  const handleSelectDays = (selectedOption) => {
    setFormValues({ ...formValues, day: selectedOption.value });
  };

  // Handle date and time input change
  const handleDateTimeChange = (event) => {
    const dateTimeValue = event.target.value;
    const date = dateTimeValue.substring(0, 10); // Extract the date
    const time = dateTimeValue.substring(11); // Extract the time

    setFormValues({ ...formValues, date, time });
  };

  return (
    <div className={styles.mainContainer}>
      <div className={displayImgUrl ? styles.displayBox : styles.fileUpload}>
        {displayImgUrl ? (
          <>
            <label className={`mt-2 ${styles.imgBoxLabel}`}>
              <img
                width={"100%"}
                height={"100%"}
                src={`http://136.244.113.52/${displayImgUrl}`}
              />
              <input
                type="file"
                className={styles.file}
                onChange={handlePhotoChange}
              />
            </label>
          </>
        ) : (
          <>
            <BsUpload fontSize={"1.4rem"} color="grey" />
            <label className={`mt-2 ${styles.label}`}>
              Subir foto
              <input
                type="file"
                style={{ zIndex: 999 }}
                className={styles.file}
                onChange={handlePhotoChange}
              />
            </label>
          </>
        )}
      </div>
      <div className={`mt-3 ${styles.nameInput}`}>
        <Input
          placeholder={"nombre de la tarea"}
          name="name"
          value={formValues.name}
          onChange={handleInputChange}
        />
      </div>
      <div className={`mt-3 ${styles.inputGroup}`}>
        <div className={` ${styles.input}`}>
          <label style={{ fontSize: "15px" }}>Descripción</label>
          <Input
            placeholder={"descripción"}
            name="description"
            value={formValues.description}
            onChange={handleInputChange}
          />
        </div>
        <div className={`${styles.input}`}>
          <label style={{ fontSize: "15px" }}>Select Date & Time</label>
          <Input
            type="datetime-local"
            placeholder="Fecha y Hora"
            name="dateTime"
            value={formValues.dateTime}
            onChange={handleDateTimeChange}
          />
        </div>
        {/* <div className={`${styles.input}`}>
          <Input
            placeholder={"comentario"}
            name="comment"
            value={formValues.comment}
            onChange={handleInputChange}
          />
        </div> */}
      </div>
      <div className={`mt-3 ${styles.inputGroup}`}>
        <div className={`${styles.input}`}>
          <Select
            onChange={handleSelectClient}
            className={styles.selectBox}
            options={client}
            placeholder="Seleccione Cliente"
          />
        </div>
        <div className={`${styles.input}`}>
          <Select
            onChange={handleSelectEmploy}
            className={styles.selectBox}
            options={employ}
            placeholder="Seleccionar empleado"
          />
        </div>
      </div>
      <div className={`mt-3 ${styles.inputGroup}`}>
        {/* <div className={`${styles.input}`}>
          <label style={{fontSize:"15px"}}>Select End Time</label>
          <Input type={"time"} placeholder={"Hora de finalización"} name="endTime" value={formValues.endTime} onChange={handleInputChange} />
        </div> */}
      </div>
      <div className={`mt-3 ${styles.inputGroup}`}>
        {/* <div className={`${styles.input}`}>
          <Input
            placeholder={"DIRECCIÓN"}
            name="address"
            value={0.address}
            onChange={handleInputChange}
          />
        </div> */}
        {/* <div className={`${styles.input}`}>
          <Select
            onChange={handleSelectJobType}
            className={styles.selectBox}
            options={jopTypeOptions}
            placeholder="El tipo de trabajo"
          />
        </div> */}
      </div>
      {/* <div className={`mt-3 ${styles.inputGroup}`}>
        <div
          className={`${styles.input} ${
            formValues?.JobType === "simple" && "d-none"
          }`}
        >
          <Select
            onChange={handleSelectDays}
            className={styles.selectBox}
            options={daysArr}
            placeholder="Seleccionar día"
          />
        </div>
      </div> */}
    </div>
  );
};

export default CreateTaskForm;
