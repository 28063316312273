import React from "react";
import styles from "./style.module.css";
// import pic1 from "../../../../Assets/list1.png";
import Heading from "../../../../Components/Heading";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

const EmployeeData = ({ employeeDetails }) => {

  // console.log(employeeDetails);

  // Calculate Age
  function calculateAge(dateOfBirth) {
    const dob = new Date(dateOfBirth);
    const dobYear = dob.getFullYear();
    const currentYear = new Date().getFullYear();
    const age = currentYear - dobYear;
    return age;
  }
  const age = calculateAge(employeeDetails?.employ?.dateOfBirth);

  return (
    <>
      <div>
        <div className={`${styles.listContainer}`}>
          <div className={`${styles.listDiv}`}>
            <h6>Employee Name</h6>
            <p>{employeeDetails?.employ?.name}</p>
          </div>
          <div className={`${styles.listDiv}`}>
            <h6>Age</h6>
            <p>{age} Years Old</p>
          </div>
          <div className={`${styles.listDiv}`}>
            <h6>Employee ID</h6>
            <p>{employeeDetails?.employId}</p>
          </div>
          <div className={`${styles.listDiv}`}>
            <h6>Gender</h6>
            <p>{employeeDetails?.employ?.gender}</p>
          </div>
          <div className={`${styles.listDiv}`}>
            <h6>Phone</h6>
            <p>{employeeDetails?.employ?.phone}</p>
          </div>
        </div>
        <div className={`${styles.listContainer}`} >
          <div className={`${styles.listDiv}`}>
            <h6>Email</h6>
            <p>{employeeDetails?.employ?.email}</p>
          </div>
          <div className={`${styles.listDiv}`}>
            <h6>City</h6>
            <p>{employeeDetails?.employ?.city}</p>
          </div>
          <div className={`${styles.listDiv}`}>
            <h6>Province</h6>
            <p>{employeeDetails?.employ?.province}</p>
          </div>
        </div>

        <div className={`${styles.descContainer}`}>
          <h6>Address</h6>
          <p>
            {employeeDetails?.employ?.address}
          </p>
        </div>
      </div >
      {
        employeeDetails?.employ?.photo &&
        <div className={`mt-3 ${styles.imgContainer}`}>
          <section>
            <Heading label="Photo" fontSize="1rem" color="#505050" />
          </section>
          <section className="d-flex">
            <div className={`mt-3 ${styles.timeContainer}`}>
              <div className={`${styles.imgContainer2}`}>
                <TransformWrapper
                  initialScale={1}
                  initialPositionX={0}
                  initialPositionY={0}
                >
                  <TransformComponent>
                    <img
                      src={`http://136.244.113.52/${employeeDetails?.employ?.photo}`}
                      width={"350px"}
                      height={"350px"}
                      alt="Employee"
                    />
                  </TransformComponent>
                </TransformWrapper>
              </div>
            </div>
          </section>
        </div>
      }
    </>
  );
};

export default EmployeeData;
