import React from "react";
import Header from "./Header";
import EmployeeData from "./EmployData";

const EmployeeDetails = ({ employeeDetails }) => {
  return (
    <section className="mt-3">
      <Header />
      <EmployeeData employeeDetails={employeeDetails} />
    </section>
  );
};

export default EmployeeDetails;
