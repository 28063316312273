import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sideActiveAction } from "../../Store/Actions/sideActiveAction";
import Header from "./Header";
import styles from "./coordinatordetails.module.css";
import Creating from "./Creating";
import Header2 from "./Header2";
import CreatePass from "./CreatePass";
import CustomButton from "../../Components/CustomButton";
import { BsPlusCircle } from "react-icons/bs";
import { createCoord } from "../../Store/Actions/coordinatorActions";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../Store/Actions/toastActions";
const CoordinatorDetails = () => {

  const [formValues, setFormValues] = useState({
    name: "",
    city: "",
    province: "",
    employData: "",
    isCitySelected: false
  });
  console.log(formValues)

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const coordinatorStates = useSelector((state) => state.coordinatorReducer);

  useEffect(() => {
    dispatch(sideActiveAction("Coordinators"));
  }, []);

  // Handle inputs change
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUploadCoordinator = () => {
    if (!formValues?.name || !formValues?.employData?.id) {
      dispatch(showToast("Required Fields are Empty", "error"));
    } else {
      const dataObj = {
        name: formValues.name,
        employId: formValues?.employData?.id,
      };
      if (formValues.isCitySelected) {
        dataObj.city = formValues.city;
      } else {
        dataObj.province = formValues.province;
      }
      // console.log(dataObj)
      dispatch(createCoord(dataObj, navigate));
    }
  };


  return (

    <div>
      <section className={` mt-4 ${styles.mainDiv}`}>
        <Header />
        <Creating
          handleInputChange={handleInputChange}
          formValues={formValues}
          setFormValues={setFormValues}
        />
      </section>
      {/* <section className={` mt-4 ${styles.mainDiv}`}>
        <Header2 />
        <CreatePass />
      </section> */}
      <div className="mt-4">
        <CustomButton
          width={"100%"}
          icon={<BsPlusCircle />}
          // label={"Upload Cordinator"}
          label={"Subir coordinador"}
          onClick={handleUploadCoordinator}
        />
      </div>
    </div>
  );
};

export default CoordinatorDetails;
