import React from "react";

const Heading = ({ label, color, fontSize }) => {
  return (
    <h2
      style={{
        color: color ? color : "#434343",
        fontSize: fontSize && fontSize,
        fontWeight: 600,
      }}
    >
      {label}
    </h2>
  );
};

export default Heading;
