import API from "../../Config/Api";
import {
    GET_CLIENT_REQUEST,
    GET_CLIENT_FAILURE,
    GET_CLIENT_SUCCESS,
    CREATE_CLIENT_REQUEST,
    CREATE_CLIENT_FAILURE,
    CREATE_CLIENT_SUCCESS,
    DELETE_CLIENT_REQUEST,
    DELETE_CLIENT_SUCCESS,
    DELETE_CLIENT_FAILURE,
    EDIT_CLIENT_REQUEST,
    EDIT_CLIENT_FAILURE,
    EDIT_CLIENT_SUCCESS
} from "../Constants/clientConsts";
import { showToast } from "./toastActions";

// Get Clients
export const getClients = () => async (dispatch) => {

    dispatch({
        type: GET_CLIENT_REQUEST
    })
    try {
        const res = await API.post('/clients');
        // console.log(res)
        dispatch({
            type: GET_CLIENT_SUCCESS,
            payload: res?.data?.data
        });
    } catch (error) {
        dispatch({
            type: GET_CLIENT_FAILURE,
            payload: error
        });
    }
};


// Create Client
export const createClient = (data, navigate) => async (dispatch) => {

    dispatch({
        type: CREATE_CLIENT_REQUEST
    })
    try {
        const response = await API.post('/clients-store', data);
        if (response.data.status === 200 || response.data.status === 201) {
            dispatch({
                type: CREATE_CLIENT_SUCCESS,
                payload: response.data
            });
            dispatch(showToast("Client Created Succesfully", "info"));
            navigate(-1)
        }
        else {
            const error = response.data;
            throw error;
        }

    } catch (error) {
        dispatch({
            type: CREATE_CLIENT_FAILURE,
            payload: error.message
        });
        dispatch(showToast(error.message, "error"));
    }
};


// Delete Client
export const deleteClient = (clientId) => async (dispatch) => {
    dispatch({
        type: DELETE_CLIENT_REQUEST,
    });

    try {
        const res = await API.post(`/clients-delete`, { clientId: clientId });
        dispatch({
            type: DELETE_CLIENT_SUCCESS,
            payload: clientId,
        });
        // console.log(res)
        dispatch(showToast("Client Deleted Successfully", "info"))
    } catch (error) {
        dispatch({
            type: DELETE_CLIENT_FAILURE,
            payload: error,
        });
        dispatch(showToast(error.message, "error"));
    }
};


// Edit Client
export const editClient = (data, navigate) => async (dispatch) => {
    dispatch({
        type: EDIT_CLIENT_REQUEST
    });
    try {
        const res = await API.post("/clients-update", data);
        dispatch({
            type: EDIT_CLIENT_SUCCESS,
            payload: res?.data
        });
        dispatch(showToast("Client Edited Successfully", "info"));
        navigate(-1)
    } catch (error) {
        dispatch({
            type: EDIT_CLIENT_FAILURE,
            payload: error
        });
        dispatch(showToast(error.message, "error"));
    }
};
