import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../Components/CustomButton";
import Heading from "../../../Components/Heading";
import styles from "./style.module.css";
import { BiAbacus } from "react-icons/bi";
import { AiOutlinePlusCircle } from "react-icons/ai";

import Modal from "react-bootstrap/Modal";
import Input from "../../../Components/Input";

function CustomModal({ show, handleClose }) {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="d-flex justify-content-center align-items-center"
      >
        <Modal.Body
          style={{
            minWidth: "600px",
            background: "#fff",
            borderRadius: 5,
          }}
        >
          <section>
            <Heading label="Filteration" />
          </section>
          <section>
            <Input placeholder="City Hall" />
          </section>
          <section className="my-3">
            <Input placeholder="Owner" />
          </section>
          <section>
            <CustomButton label="Filter" width="100%" />
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
}

const Header = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className={styles.header}>
      <CustomModal show={show} handleClose={handleClose} />
      <section>
        <Heading
          label="¡Hola mucho gusto!"
          fontSize="0.8rem"
          color="#6E528D"
        />
        {/* <Heading label="Edit Cordinator" fontSize="1.3rem" color="#6E528D" /> */}
        <Heading label="Coordinadora de edición" fontSize="1.3rem" color="#6E528D" />
      </section>
    </div>
  );
};

export default Header;
