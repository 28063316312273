import React from "react";
import styles from "./style.module.css";
import pic1 from "../../../../Assets/list1.png";
import pic3 from "../../../../Assets/list3.png";
import Heading from "../../../../Components/Heading";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"

const TaskData = ({ taskDetails }) => {

  // console.log(taskDetails);

  return (
    <>
      <div>
        <div className={`${styles.listContainer}`}>
          <div className={`${styles.listDiv}`}>
            <h6>Task Name</h6>
            <p>{taskDetails?.name}</p>
          </div>
          <div className={`${styles.listDiv}`}>
            <h6>Status</h6>
            <p>{taskDetails?.status ? taskDetails?.status : "Pending"}</p>
          </div>
          <div className={`${styles.listDiv}`}>
            <h6>Date</h6>
            <p>{taskDetails?.date}</p>
          </div>
        </div>
        <div className={`${styles.descContainer}`}>
          <h6>Home Address</h6>
          <p>{taskDetails?.client?.address}</p>
        </div>
        <div className={`${styles.descContainer}`}>
          <h6>Comments</h6>
          <p>
            {taskDetails?.description}
          </p>
        </div>

        <div className={`mt-3 ${styles.imgContainer}`}>
          <h5>Task Photos</h5>
          <div>
            {taskDetails?.photos?.map((item, ind) => (
              <TransformWrapper
                key={ind}
                initialScale={1}
                initialPositionX={0}
                initialPositionY={0}
              >
                <TransformComponent>
                  <img
                    src={`http://136.244.113.52/${item}`}
                    width={"350px"}
                    height={"350px"}
                    alt="Task"
                  />
                </TransformComponent>
              </TransformWrapper>
            ))}
          </div>
        </div>
        {/* <div className={`mt-3 ${styles.imgContainer}`}>
          <h5>Task Photos</h5>
          <div>
            {taskDetails?.photos?.map((item) => (
              // <img src={`http://136.244.113.52/${item}`} alt={"pic1"} />
              <img src={pic3} alt={"pic3"} />
            ))}
          </div>
        </div> */}
      </div>
    </>
  );
};

export default TaskData;
