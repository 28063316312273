import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import Marker from "../../../Assets/manIcon.png";
// import styles from "./style.module.css";

const AnyReactComponent = ({ text, zoom }) => (
  <div
    style={{ zIndex: 222, position: "relative", bottom: "20px", right: "20px" }}
    // className={styles.marker}
  >
    <img src={Marker} width={`${zoom > 7 ? "20px" : "30px"}`} alt="Marker " />
    {/* {text} */}
  </div>
);

const GoogleMap = ({ lat, lng, isCountry, onChange, mapType = "roadmap" }) => {
  const [center, setCenter] = useState({
    lat: 40.4168,
    lng: 3.7038,
  });
  const [zoom, setZoom] = useState(0);

  useEffect(() => {
    if (lat && lng) {
      setCenter({
        lat: Number(lat),
        lng: Number(lng),
      });
    }
  }, [lat, lng]);

  return (
    <>
      <div style={{ height: "80vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyCm2F_3p5FSR1pPKRznter_3QkM_LTbR6A" }}
          center={center}
          onClick={(e) => onChange(e)}
          onZoomAnimationStart={(e) => setZoom(e)}
          zoom={center.lat && center.lng ? (isCountry ? 4 : 5) : -50}
          options={function (maps) {
            return { mapTypeId: mapType };
          }}
        >
          {center.lat && center.lng && !isCountry && (
            <AnyReactComponent lat={lat} lng={lng} zoom={zoom} />
          )}
        </GoogleMapReact>
      </div>
    </>
  );
};

export default GoogleMap;
