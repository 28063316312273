import API from "../../Config/Api";
import {
    GET_COORDS_REQUEST,
    GET_COORDS_FAILURE,
    GET_COORDS_SUCCESS,
    CREATE_COORD_REQUEST,
    CREATE_COORD_FAILURE,
    CREATE_COORD_SUCCESS,
    DELETE_COORD_REQUEST,
    DELETE_COORD_SUCCESS,
    DELETE_COORD_FAILURE,
    EDIT_COORD_REQUEST,
    EDIT_COORD_FAILURE,
    EDIT_COORD_SUCCESS
} from "../Constants/coordinatorConsts";
import { showToast } from "./toastActions";

// Get Coordinators
export const getCoords = () => async (dispatch) => {

    dispatch({
        type: GET_COORDS_REQUEST
    })
    try {
        const res = await API.post('/cords');
        // console.log(res)
        dispatch({
            type: GET_COORDS_SUCCESS,
            payload: res?.data?.data
        });
    } catch (error) {
        dispatch({
            type: GET_COORDS_FAILURE,
            payload: error
        });
        dispatch(showToast(error.message, "error"));
    }
};


// Create Coordinators
export const createCoord = (data, navigate) => async (dispatch) => {

    dispatch({
        type: CREATE_COORD_REQUEST
    })
    try {
        const response = await API.post('/cords-store', data);
        if (response.data.status === 200 || response.data.status === 201) {
            dispatch({
                type: CREATE_COORD_SUCCESS,
                payload: response.data
            });
            dispatch(showToast("Coordinator Created Succesfully", "info"));
            navigate(-1);
        } else {
            const error = response.data;
            throw error;
        }
    } catch (error) {
        dispatch({
            type: CREATE_COORD_FAILURE,
            payload: error.message
        });
        dispatch(showToast(error.message, "error"));
    }
};


// Delete Coordinators
export const deleteCoord = (coordinatorId) => async (dispatch) => {
    dispatch({
        type: DELETE_COORD_REQUEST,
    });

    try {
        const res = await API.post(`/cords-delete`, { coordinatorId: coordinatorId });
        dispatch({
            type: DELETE_COORD_SUCCESS,
            payload: coordinatorId,
        });
        console.log(res)
        dispatch(showToast("Coordinator Deleted Successfully", "info"))

    } catch (error) {
        dispatch({
            type: DELETE_COORD_FAILURE,
            payload: error,
        });
        dispatch(showToast(error.message, "error"));
    }
};


// Edit Coordinators
export const editCoord = (data, navigate) => async (dispatch) => {
    dispatch({
        type: EDIT_COORD_REQUEST
    });
    try {
        const res = await API.post("/cords-update", data);
        dispatch({
            type: EDIT_COORD_SUCCESS,
            payload: res?.data
        });
        dispatch(showToast("Coordinator Edited Successfully", "info"))
        navigate(-1);
    } catch (error) {
        dispatch({
            type: EDIT_COORD_FAILURE,
            payload: error
        });
        dispatch(showToast(error.message, "error"));
    }
};
