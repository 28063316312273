export const CREATE_TASK_REQUEST = 'CREATE_TASK_REQUEST';
export const CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS';
export const CREATE_TASK_FAILURE = 'CREATE_TASK_FAILURE';

export const GET_TASK_REQUEST = 'GET_TASK_REQUEST';
export const GET_TASK_SUCCESS = 'GET_TASK_SUCCESS';
export const GET_TASK_FAILURE = 'GET_TASK_FAILURE';

export const DELETE_TASK_REQUEST = 'DELETE_TASK_REQUEST';
export const DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS';
export const DELETE_TASK_FAILURE = 'DELETE_TASK_FAILURE';

export const GET_EMPLOYEE_TASK_REQUEST = 'GET_EMPLOYEE_TASK_REQUEST';
export const GET_EMPLOYEE_TASK_SUCCESS = 'GET_EMPLOYEE_TASK_SUCCESS';
export const GET_EMPLOYEE_TASK_FAILURE = 'GET_EMPLOYEE_TASK_FAILURE';
