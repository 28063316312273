import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../../Components/CustomButton";
import Heading from "../../../../Components/Heading";
import styles from "./style.module.css";
import { BiAbacus } from "react-icons/bi";
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineEye,
  AiOutlinePlusCircle,
} from "react-icons/ai";

import Modal from "react-bootstrap/Modal";
import Input from "../../../../Components/Input";
import CustomSelect from "../../../../Components/CustomSelect";

function CustomModal({ show, handleClose }) {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="d-flex justify-content-center align-items-center"
      >
        <Modal.Body
          style={{
            minWidth: "600px",
            background: "#fff",
            borderRadius: 5,
          }}
        >
          <section>
            <Heading label="Filteration" />
          </section>
          <section>
            <Input placeholder="Title" />
          </section>
          <section className="my-3">
            {/* <Input placeholder="Category" /> */}
            <CustomSelect width={"100%"}>
              <option>Category</option>
              <option>
                <AiOutlineEye color="#fff" />
                View
              </option>
              <option>
                <AiOutlineEdit color="#fff" /> Edit
              </option>
              <option>
                <AiOutlineDelete color="#fff" /> Delete
              </option>
            </CustomSelect>
          </section>
          <section className="my-3">
            <Input placeholder="Date" />
          </section>
          <section className="my-3">
            <Input placeholder="Status" />
          </section>
          <section>
            <CustomButton label="Filter" width="100%" />
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
}

const Header = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className={styles.header}>
      <CustomModal show={show} handleClose={handleClose} />
      <section>
        <Heading label="Task Details" fontSize="1rem" color="#505050" />
      </section>
    </div>
  );
};

export default Header;
