import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { sideActiveAction } from "../../Store/Actions/sideActiveAction";
import Header from "./Header";
import styles from "./createcoordinator.module.css";
import Creating from "./Creating";
import Header2 from "./Header2";
import CreatePass from "./CreatePass";
import { BsPlusCircle } from "react-icons/bs";
import CustomButton from "../../Components/CustomButton";
import { createEmployee } from "../../Store/Actions/employeesActions";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../Store/Actions/toastActions";
const CreateEmployee = () => {

  const [formValues, setFormValues] = useState({
    name: "",
    phone: "",
    nif: "",
    dateOfBirth: "",
    gender: "",
    address: "",
    password: "",
    cnfrmPass: "",
    photo: null,
    email: "",
    city: "",
    province: ""
  });
  console.log(formValues)

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const employeesState = useSelector((state) => state.employeesReducer);
  console.log(employeesState);

  useEffect(() => {
    dispatch(sideActiveAction("Employees"));
  }, []);

  // Handle inputs change
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePhotoChange = (event) => {
    const file = event.target.files[0];
    setFormValues((prevState) => ({
      ...prevState,
      photo: file,
    }));
  };

  // Handle Upload Employee
  const handleUploadEmployee = () => {
    if (
      !formValues?.name ||
      !formValues?.phone ||
      !formValues?.nif ||
      !formValues?.dateOfBirth ||
      !formValues?.gender ||
      !formValues?.address ||
      !formValues?.password ||
      !formValues?.cnfrmPass ||
      !formValues?.email ||
      !formValues?.province ||
      !formValues?.city
    ) {
      dispatch(showToast("Required Fields are Empty", "error"));
    }
    else {
      if (formValues?.password === formValues?.cnfrmPass) {

        // Create Form Data for Sending
        const formData = new FormData();
        formData.append("name", formValues?.name);
        formData.append("phone", formValues?.phone);
        formData.append("nif", formValues?.nif);
        formData.append("dateOfBirth", formValues?.dateOfBirth);
        formData.append("gender", formValues?.gender);
        formData.append("address", formValues?.address);
        formData.append("password", formValues?.password);
        formData.append("photo", formValues?.photo);
        formData.append("email", formValues?.email);
        formData.append("province", formValues?.province);
        formData.append("city", formValues?.city);

        dispatch(createEmployee(formData, navigate));
      }
      else {
        dispatch(showToast("Password and Confirm Password Should be Equal", "error"));
      }

    }
  }

  return (

    <div>
      <section className={` mt-4 ${styles.mainDiv}`}>
        <Header />
        <Creating
          handleInputChange={handleInputChange}
          handlePhotoChange={handlePhotoChange}
          formValues={formValues}
          setFormValues={setFormValues}
        />
      </section>
      <section className={` mt-4 ${styles.mainDiv}`}>
        <Header2 />
        <CreatePass
          handleInputChange={handleInputChange}
          formValues={formValues}
          setFormValues={setFormValues}
        />
      </section>
      <div className={
        employeesState?.isLoading
          ? "d-flex justify-content-center align-items-center mt-4"
          : "mt-4"
      }>
        {
          employeesState?.isLoading
            ? <Spinner style={{ color: "#6e528d" }} />
            : <CustomButton
              width={"100%"}
              icon={<BsPlusCircle />}
              // label={"Upload Employee"}
              label={"Subir empleada"}
              onClick={handleUploadEmployee}
            />
        }
      </div>
    </div>
  );
};

export default CreateEmployee;
