import React from "react";
import styles from "./style.module.css";

import logo from "../../Assets/logo.png";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <button
      href=""
      ref={ref}
      onClick={(e) => {
        onClick(e);
      }}
      className={styles.btn}
    >
      {children}
    </button>
  ));
  const handleLogout = async () => {
    navigate("/");
  };
  return (
    <nav className={styles.navbar}>
      <section className="d-flex align-items-center">
        {/* <div>
          <FiMenu fontSize={25} />
        </div> */}
        <div style={{ marginLeft: "20px" }}>
          <img
            src={logo}
            alt="logo"
            style={{
              width: "76px",
              height: "7vh",
            }}
          />
        </div>
      </section>
      <section className={styles.user}>{/* <h4>V</h4> */}</section>
      <Dropdown>
        <Dropdown.Toggle
          style={{
            backgroundColor: "transparent !important",
            border: "1px solid red !important",
          }}
          as={CustomToggle}
        >
          <img
            src={logo}
            alt="logo"
            style={{
              width: "48px",
              height: "4vh",
            }}
          />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => handleLogout()}>Logout</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </nav>
  );
};

export default Navbar;
