import {
    GET_CLIENT_REQUEST,
    GET_CLIENT_FAILURE,
    GET_CLIENT_SUCCESS,
    CREATE_CLIENT_REQUEST,
    CREATE_CLIENT_FAILURE,
    CREATE_CLIENT_SUCCESS,
    DELETE_CLIENT_REQUEST,
    DELETE_CLIENT_FAILURE,
    DELETE_CLIENT_SUCCESS,
    EDIT_CLIENT_FAILURE,
    EDIT_CLIENT_REQUEST,
    EDIT_CLIENT_SUCCESS
} from "../Constants/clientConsts";

const initialState = {
    clients: [],
    createClientRes: "",
    // deleteClientRes: "",
    editClientRes: "",
    isLoading: false,
    error: null
};

export const clientsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CLIENT_REQUEST:
        case CREATE_CLIENT_REQUEST:
        case DELETE_CLIENT_REQUEST:
        case EDIT_CLIENT_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case GET_CLIENT_FAILURE:
        case CREATE_CLIENT_FAILURE:
        case DELETE_CLIENT_FAILURE:
        case EDIT_CLIENT_FAILURE:
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };
        case GET_CLIENT_SUCCESS:
            return {
                ...state,
                clients: action.payload,
                isLoading: false
            };
        case CREATE_CLIENT_SUCCESS:
            return {
                ...state,
                createClientRes: action.payload,
                isLoading: false
            };
        case DELETE_CLIENT_SUCCESS:
            const updatedclients = state.clients.filter(
                (client) => client.id !== action.payload
            );
            return {
                ...state,
                clients: updatedclients,
                isLoading: false,
            };
        case EDIT_CLIENT_SUCCESS:
            return {
                ...state,
                editClientRes: action.payload,
                isLoading: false,
            };
        default:
            return state;
    }
};
