import React from "react";
import Header from "./Header";
import ServiceData from "./ServiceData";

const ServiceTime = ({ serviceTime }) => {
  return (
    <>
      {/* <Header /> */}
      <ServiceData serviceTime={serviceTime} />
    </>
  );
};

export default ServiceTime;
