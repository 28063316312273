import React, { useState } from "react";
import {
  AiOutlineCheck,
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineEye,
} from "react-icons/ai";
import styles from "./style.module.css";
import Switch from "react-switch";
import CustomSelect from "../../../Components/CustomSelect";

const Creating = ({ tableData, headerData }) => {

  return (
    <>
      <div className={`${styles.mainContainer}`}>
        <div className={`${styles.dropDown}`}>
          <CustomSelect width={"100%"}>
            <option>Select Employee</option>
            <option>
              <AiOutlineEye color="#fff" />
              View
            </option>
            <option>
              <AiOutlineEdit color="#fff" /> Edit
            </option>
            <option>
              <AiOutlineDelete color="#fff" /> Delete
            </option>
          </CustomSelect>
        </div>
      </div>
    </>
  );
};

export default Creating;
