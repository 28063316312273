import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { sideActiveAction } from "../../Store/Actions/sideActiveAction";
import Header from "./Header";
import styles from "./addclients.module.css";
import Creating from "./Creating";
import Header2 from "./Header2";
import CreatePass from "./CreatePass";
import { BsPlusCircle } from "react-icons/bs";
import CustomButton from "../../Components/CustomButton";
import GoogleMap from "../Create Task/GoogleMap";
import AutoComplete from "../Create Task/AutoComplete";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { createClient } from "../../Store/Actions/clientActions";
import { showToast } from "../../Store/Actions/toastActions";

const AddClient = () => {
  const [formValues, setFormValues] = useState({
    name: "",
    phone: "",
    email: "",
    dateOfBirth: "",
    province: "",
    lat: "",
    long: "",
    address: "",
    password: "",
    cnfrmPass: "",
  });
  const [isCountry, setIsCountry] = useState(false);

  // console.log(formValues)

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const clientState = useSelector((state) => state.clientsReducer);
  console.log(clientState);

  useEffect(() => {
    dispatch(sideActiveAction("Clients"));
  }, []);

  // Handle inputs change
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handlePlaceChange = (e) => {
    setFormValues({
      ...formValues,
      ["lat"]: e.lat,
      ["long"]: e.lng,
      ["address"]: e.address,
    });
    setIsCountry(e.isCountry);
  };

  const handleOnMapChaange = (data) => {
    setFormValues({
      ...formValues,
      ["lat"]: data.lat,
      ["long"]: data.lng,
    });
  };
  const handleSetAddress = (e) =>
    setFormValues({ ...formValues, ["address"]: e });

  const handleUploadClient = () => {
    console.log(formValues);
    // return
    if (
      !formValues?.name ||
      !formValues?.phone ||
      !formValues?.email ||
      !formValues?.dateOfBirth ||
      !formValues?.lat ||
      !formValues?.long ||
      !formValues?.address ||
      !formValues?.password ||
      !formValues?.province ||
      !formValues?.cnfrmPass
    ) {
      dispatch(showToast("Required Fields are Empty", "error"));
    } else {
      if (formValues?.password === formValues?.cnfrmPass) {
        const data = {
          name: formValues?.name,
          phone: formValues?.phone,
          province: formValues?.province,
          email: formValues?.email,
          dateOfBirth: formValues?.dateOfBirth,
          latitude: formValues?.lat,
          longitude: formValues?.long,
          address: formValues?.address,
          password: formValues?.password,
        };

        dispatch(createClient(data, navigate));
      } else {
        dispatch(
          showToast("Password and Confirm Password Should be Equal", "error")
        );
      }
    }
  };

  return (
    <div>
      <section className={` mt-4 ${styles.mainDiv}`}>
        <Header />
        <Creating
          handleInputChange={handleInputChange}
          formValues={formValues}
          setFormValues={setFormValues}
        />
      </section>
      <section className={` mt-4 ${styles.mainDiv}`}>
        {/* <GoogleMap formValues={formValues} setFormValues={setFormValues} /> */}
        <AutoComplete
          onChange={handlePlaceChange}
          address={formValues?.address}
          setAddress={handleSetAddress}
        />
        <GoogleMap
          onChange={handleOnMapChaange}
          lat={formValues?.lat}
          lng={formValues?.long}
          isCountry={isCountry}
        />
      </section>
      <section className={` mt-4 ${styles.mainDiv}`}>
        <Header2 />
        <CreatePass
          handleInputChange={handleInputChange}
          formValues={formValues}
          setFormValues={setFormValues}
        />
      </section>
      <div
        className={
          clientState?.isLoading
            ? "d-flex justify-content-center align-items-center mt-4"
            : "mt-4"
        }
      >
        {clientState?.isLoading ? (
          <Spinner style={{ color: "#6e528d" }} />
        ) : (
          <CustomButton
            width={"100%"}
            icon={<BsPlusCircle />}
            // label={"Upload Client"}
            label={"Cargar cliente"}
            onClick={handleUploadClient}
          />
        )}
      </div>
    </div>
  );
};

export default AddClient;
