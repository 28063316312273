import React, { useState } from "react";
import {
  AiOutlineCheck,
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineEye,
} from "react-icons/ai";
import styles from "./style.module.css";
import Switch from "react-switch";
import CustomSelect from "../../../Components/CustomSelect";
import { useDispatch } from "react-redux";
import { deleteEmployee } from "../../../Store/Actions/employeesActions";
import { useNavigate } from "react-router-dom";

const DataTable = ({ tableData, headerData, selectedDate }) => {
  const [status, setStatus] = useState("active");
  const toggleStatus = () => {
    setStatus((curr) => (curr === "active" ? "Inactive" : "active"));
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Handle Delete Employee
  const handleDeleteEmployee = (employeeId) => {
    dispatch(deleteEmployee(employeeId));
  };
  return (
    <div className={styles.tableParent}>
      <table>
        <thead>
          <tr>
            <td>
              <input type="checkbox" className={styles.checkBox} />
            </td>
            {headerData?.map((value) => {
              return (
                <td
                  // style={{width:"150px"}}
                  key={value}
                  className={styles.tHeading}
                >
                  {value}
                </td>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {tableData?.map((value) => {

            // console.log(value)
            const taskDate = value?.date;

            if (!selectedDate || selectedDate === taskDate) {
              const client = value?.client;
              const employee = value?.employ;

              return (
                <tr key={value?.id}>
                  <td>
                    <input type="checkbox" className={styles.checkBox} />
                  </td>
                  <td>{value?.name}</td>
                  <td>{client?.name}</td>
                  <td>{employee?.name}</td>
                  <td>{taskDate}</td>
                  <td>{value?.status ? value?.status : "Pending"}</td>
                </tr>
              );
            } else {
              // if selectedDate is set but does not match taskDate, exclude the row
              return null;
            }
          })}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;
